import React, { ComponentType, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { Stack } from '@mui/material'

import { Box, ContactAndAssignment, FormCard, Text } from 'src/components'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { getActionLabel } from 'src/helpers/labels'
import { CreateContact } from 'src/modules/my-tasks-v3/components/actions/create-contact'
import { FileUpload } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-upload'
import { FileTemplate } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-template'
import { TaskContext } from 'src/providers'
import { VideoEmbed } from 'src/modules/my-tasks-v3/components/actions/video-embed'
import { Software } from 'src/modules/my-tasks-v3/components/actions/software'
import { TaskReference } from 'src/modules/my-tasks-v3/components/actions/task-reference'
import { Template } from 'src/modules/my-tasks-v3/components/actions/template-modal'
import { AsyncDispatchType } from 'src/store/types'
import { QuestionFlowLogicContainer } from 'src/components/question-flow/question-flow-logic-container'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'

import { SetResources } from '../../../../my-tasks-v3/components/actions/set-resources'

import { TaskAttachments } from './task-attachments'

const Action: ComponentType<{ action: V3ClientTypes.Project.TaskAction }> = ({ action }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { task, updateTask } = useContext(TaskContext)
	const project = useSelector(projectSelectors.getSelectedProject)
	const taskRoles = useSelector(projectSelectors.getTaskRoles)
	const taskExternals = useSelector(projectSelectors.getTaskExternals)
	const actionType = action.type

	const createContact = (contactId: string) => {
		if (!task) return
		updateTask('lifecycle.resource', contactId, { updateDb: true })
	}

	const handleRoleAssignChange = async (roleId: string, userId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignUserToRole({
				userId,
				roleId,
				projectId: task.project_id,
			}),
		)
	}

	const handleExternalAssignChange = async (roleId: string, contactId: string) => {
		if (!task) return
		await dispatch(
			projectActions.assignContactToExternal({
				projectId: task.project_id,
				assignments: [{ roleId, contactId }],
			}),
		)
	}

	if (!task || !project) return null

	switch (actionType) {
		case V3BlueprintTypes.ActionEnum.FileUpload:
			return <FileUpload project={project} task={task} action={action} />
		case V3BlueprintTypes.ActionEnum.FileTemplate:
			return <FileTemplate task={task} project={project} action={action} />
		case V3BlueprintTypes.ActionEnum.Call:
		case V3BlueprintTypes.ActionEnum.Email:
		case V3BlueprintTypes.ActionEnum.Invite:
		case V3BlueprintTypes.ActionEnum.Meeting:
			return (
				<Box>
					<ContactAndAssignment
						taskExternals={taskExternals}
						taskRoles={taskRoles}
						onAssignExternal={handleExternalAssignChange}
						onAssignRole={handleRoleAssignChange}
					/>
				</Box>
			)
		case V3BlueprintTypes.ActionEnum.Software:
			return <Software links={action.links} />
		case V3BlueprintTypes.ActionEnum.Video: {
			return <VideoEmbed urls={action.links} />
		}
		case V3BlueprintTypes.ActionEnum.TaskReference:
			return <TaskReference taskReferences={action.task_references} project={project} />
		case V3BlueprintTypes.ActionEnum.Note:
			return (
				<Box sx={{ overflowWrap: 'break-word' }}>
					<Text fontSize={14}>{action.note}</Text>
				</Box>
			)
		case V3BlueprintTypes.ActionEnum.Contact:
			return <CreateContact task={task} useDrawer onCreateContact={createContact} />
		case V3BlueprintTypes.ActionEnum.SetResources:
			return <SetResources action={action} />
		case V3BlueprintTypes.ActionEnum.Template:
			return <Template action={action} />
		case V3BlueprintTypes.ActionEnum.Questionaire:
			return (
				<QuestionFlowLogicContainer
					filters={{
						...task.chain?.database_chain_logic?.filters,
						...action.questionaire?.filters,
						items: [
							...(task.chain?.database_chain_logic?.filters?.items ?? []),
							...(action.questionaire?.filters.items ?? []),
						],
					}}
					acceptedQuestionColumns={action.questionaire?.columns ?? []}
					questionScope={action.questionaire?.scope ?? []}
				/>
			)
		default:
			return null
	}
}

export const ActionFields: ComponentType = () => {
	const { task } = useContext(TaskContext)

	const taskAttachmentDirection =
		useSelector(persistedConfigSelectors.getTaskAttachmentDirection) ?? 'vertical'

	if (!task) {
		return null
	}

	return (
		<>
			{/* Render only attachments in case there is no actions */}
			{task.actions.length === 0 && !!task.attachments.length && (
				<TaskAttachments attachments={task.attachments} />
			)}
			{task.actions.map((_action) => {
				if (_action.type === V3BlueprintTypes.ActionEnum.None) {
					return null
				}
				return (
					<Box key={_action._id} mt={2} pb={2}>
						<Stack direction={taskAttachmentDirection} spacing={2}>
							<FormCard title={`Action - ${getActionLabel(_action.type)}`}>
								<Action action={_action} />
							</FormCard>
							<TaskAttachments attachments={task.attachments ?? []} />
						</Stack>
					</Box>
				)
			})}
		</>
	)
}
