import { ComponentType, useContext, useMemo } from 'react'
import { V3ClientTypes, V3ProjectTypes } from '@cango-app/types'
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium'

import { TableContext, TaskContext } from 'src/providers'
import { DisplayView } from 'src/components/display-view'

import { getFilters } from '../../../tables/utils'

type TableContentProps = {
	action: V3ClientTypes.Project.TaskAction
	forceHide?: boolean
}

const TableContent: ComponentType<TableContentProps> = ({ action }) => {
	const { task } = useContext(TaskContext)
	const { tableConfig, mappedColumns } = useContext(TableContext)

	const { view, initialState, filters } = useMemo(() => {
		const view = tableConfig?.views.find((_view) => _view._id === action.view)
		const chains =
			task?.chain?.parent_chains?.reduce(
				(_chains: V3ProjectTypes.ProjectChain[], _parentChain) => {
					return [..._chains, _parentChain]
				},
				task?.chain ? [task?.chain] : [],
			) ?? []
		const filters = getFilters({
			chains,
			mappedColumns,
			viewFilters: view?.filters?.items,
		})
		const initialState: GridInitialStatePremium = {
			filter: {
				filterModel: {
					items: filters,
				},
			},
		}
		return {
			view,
			initialState,
			filters,
		}
	}, [tableConfig?.views, action.view])

	if (!view) {
		return null
	}

	return <DisplayView {...view} initialState={initialState} filters={{ items: filters }} />
}

export const SetResources = TableContent
