import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { selectors as projectSelectors, ProjectsLoadingState } from 'src/store/modules/projects-v3'
import { useIsMobile } from 'src/hooks/useMobile'

import { ErrorMyTasks } from './error'
import { MyTasksDesktopContainer } from './my-tasks-desktop-container'
import { MyTasksMobileContainer } from './my-tasks-mobile-container'

const MyTasksV3: ComponentType = () => {
	const hasError = useSelector(projectSelectors.getTasksState) === ProjectsLoadingState.Error
	const isMobile = useIsMobile()

	if (hasError) {
		return <ErrorMyTasks />
	}

	if (isMobile) {
		return <MyTasksMobileContainer />
	}

	return <MyTasksDesktopContainer />
}

export default MyTasksV3
