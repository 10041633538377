import React, { ComponentType, useContext, useMemo, useState } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'
import { FormProvider, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import Alert from '@mui/material/Alert'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormLabel from '@mui/material/FormLabel'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import _isString from 'lodash/isString'

import { Box, Button, Divider, IconButton, Modal, Text } from 'src/components'
import { CloseIcon } from 'src/assets/icons'
import { getThreads } from 'src/modules/chains/components/utils'
import { ChainEndingsSelection } from 'src/modules/chains/components/chain-dependency-edge/chain-endings-selection'

import { ChainContext } from '../../chain-provider'

import { DependencyForm, DependencyModalProps, DependencyType } from './types'
import { DependencyFromDatabase } from './dependency-from-database'
import { ChainForEachOption } from './chain-for-each-option'
import { ChainForEveryOptionSelected } from './chain-for-every-option-selected'
import { MultiUseConfig } from './multi-use-config'

const getSelectedDependencyOption = ({
	createForEveryOption,
	option_condition,
}: {
	createForEveryOption?: boolean
	option_condition: V3BlueprintTypes.StepChild['option_condition']
}) => {
	if (!option_condition) {
		return DependencyType.WHEN_THIS_TASK_COMPLETES
	}

	if (createForEveryOption) {
		return DependencyType.CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED
	}

	return DependencyType.A_UNIQUE_CHAIN_FOR_EACH_OPTION_SELECTED
}

export const DependencyModal: ComponentType<DependencyModalProps> = (props) => {
	const { onClose, source, onDeleteConnection, child } = props
	const [isUpdatingConnection, setIsUpdatingConnection] = useState(false)
	const { onUpdateConnection, nodeMap, nodes } = useContext(ChainContext)
	const formProps = useForm<DependencyForm>({
		defaultValues: {
			createForEveryOption: child.createForEveryOption ?? false,
			chainPrefix: child.thread?.prefix ?? '',
			databaseLogic: child.database_chain_logic,
			option_condition: child.option_condition,
			multiUseConfig: child.multi_use_config,
			database_changes: child.database_changes,
			chain_endings: child.chain_endings ?? [],
		},
	})

	const { setValue, handleSubmit, watch } = formProps
	const [optionCondition, formCreateForEveryOption, databaseLogic, multiUseConfig] = watch([
		'option_condition',
		'createForEveryOption',
		'databaseLogic',
		'multiUseConfig',
	])
	const fromNodeId = optionCondition?.from ?? source
	const fromNode = nodeMap.get(fromNodeId)
	const isDuplicateChainDisabled = formCreateForEveryOption && !!databaseLogic

	const allThreads = useMemo(() => {
		return getThreads({
			nodeMap,
			nodeId: source,
		})
	}, [nodes, source])

	const dependencyType = useMemo((): DependencyType => {
		return getSelectedDependencyOption({
			createForEveryOption: formCreateForEveryOption,
			option_condition: optionCondition,
		})
	}, [optionCondition, formCreateForEveryOption])

	const handleSaveDependencyType = async (data: DependencyForm) => {
		if (!child.step && !child.chain_complete_point) {
			return
		}

		setIsUpdatingConnection(true)

		let updatedThread: V3BlueprintTypes.Thread | null = null

		if (data.createForEveryOption || data.chainPrefix) {
			const prefix = _isString(fromNode?.data.complete_options?.options)
				? fromNode?.data.complete_options.options
				: (data.chainPrefix ?? child.thread?.prefix ?? '')
			updatedThread = {
				_id: child.thread?._id || v4(),
				prefix,
			}
		} else if (!data.chainPrefix && !data.createForEveryOption) {
			updatedThread = null
		}

		await onUpdateConnection({
			_id: child._id,
			connection: { source, target: child.step },
			method: 'update',
			option_condition: data.option_condition,
			createForEveryOption: data.createForEveryOption,
			thread: updatedThread,
			databaseLogic: data.databaseLogic,
			multiUseConfig: data.multiUseConfig,
			databaseChanges: data.database_changes ?? null,
			chain_endings: data.chain_endings,
		})

		setIsUpdatingConnection(false)
		onClose()
	}

	const handleDependencyTypeChange = (previousType: DependencyType, newType: DependencyType) => {
		if (newType === DependencyType.WHEN_THIS_TASK_COMPLETES) {
			setValue('option_condition', undefined, { shouldDirty: true })
			setValue('createForEveryOption', false)
			setValue('chainPrefix', '')
			setValue('database_changes', null)
			return
		}

		if (previousType === DependencyType.WHEN_THIS_TASK_COMPLETES) {
			setValue('option_condition', {
				values: [],
				operator: V3BlueprintTypes.ChildConditionOperator.Is,
			})
		}

		if (newType === DependencyType.CREATE_THE_SAME_CHAIN_FOR_EVERY_OPTION_SELECTED) {
			setValue('createForEveryOption', true)
		} else {
			setValue('createForEveryOption', false)
		}
	}

	return (
		<FormProvider {...formProps}>
			<Modal open={true}>
				<Box maxWidth={700} minWidth={400}>
					<Box position="absolute" right={16}>
						<IconButton onClick={onClose}>
							<CloseIcon width={12} />
						</IconButton>
					</Box>
					<Text variant="h6" mb={2}>
						Change dependency type
					</Text>
					{isDuplicateChainDisabled && (
						<Alert severity="error" sx={{ mb: 2 }}>
							You cannot have both database logic and duplicate a chain for each option selected, as
							it is conflicting logic. If you need to use database logic, please create a normal
							condition without duplicating the chain.
						</Alert>
					)}
					<Box sx={{ width: '100%' }}>
						<FormControl sx={{ maxWidth: 600 }}>
							<FormLabel id="radio-buttons-dependencies">I want the next task to start:</FormLabel>
							<RadioGroup
								aria-labelledby="radio-buttons-dependencies"
								value={dependencyType}
								onChange={(e, value) =>
									handleDependencyTypeChange(dependencyType, value as DependencyType)
								}
							>
								<FormControlLabel
									value={DependencyType.WHEN_THIS_TASK_COMPLETES}
									control={<Radio />}
									label={<Text fontSize={14}>{'when my task completes'}</Text>}
								/>
								<ChainForEachOption dependencyType={dependencyType} source={source} />
								{!multiUseConfig && (
									<ChainForEveryOptionSelected
										dependencyType={dependencyType}
										disabled={!!databaseLogic}
										source={source}
									/>
								)}
							</RadioGroup>
							{!multiUseConfig && (
								<>
									<Divider sx={{ py: 1 }} />
									<DependencyFromDatabase />
								</>
							)}
							{!!multiUseConfig && <MultiUseConfig target={child.step} />}
							{!!allThreads.length && (
								<>
									<Divider sx={{ pb: 1 }} />
									<ChainEndingsSelection allThreads={allThreads} />
								</>
							)}
						</FormControl>
					</Box>
					<Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
						<Button
							onClick={handleSubmit(handleSaveDependencyType)}
							isLoading={isUpdatingConnection}
							disabled={isUpdatingConnection}
						>
							Save
						</Button>
						<Button variant="outlined" color="error" onClick={onDeleteConnection}>
							Delete connection
						</Button>
					</Box>
				</Box>
			</Modal>
		</FormProvider>
	)
}
