import { ComponentType, useContext, useState } from 'react'
import dayjs, { ManipulateType } from 'dayjs'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import { Box, RemoveFlaggedTaskModal, TextField } from 'src/components'
import { TaskContext } from 'src/providers'
import { useListOptions } from 'src/hooks/use-list-options'
import { OptionsSelect } from 'src/modules/my-tasks-v3/components/complete-task-cta/options-select'
import { DatabaseModInputs } from 'src/modules/my-tasks-v3/components/complete-task-cta/database-mod-inputs'
import { CompleteCta } from 'src/modules/my-tasks-v3/components/complete-task-cta/complete-cta'
import { TimeWarningModal } from 'src/modules/my-tasks-v3/components/complete-task-cta/time-warning-modal'

import { CustomInputForm, OnPostCompleteTaskProps, WarningState } from './types'

type CompleteTaskButtonProps = {
	onPreComplete?: () => 'continue' | 'cancel'
	onPostComplete?: (data: OnPostCompleteTaskProps) => void
	sectionId?: string
}

export const CompleteTaskButton: ComponentType<CompleteTaskButtonProps> = ({
	onPreComplete,
	onPostComplete,
}) => {
	const { task, selectedOptions } = useContext(TaskContext)
	const [warningState, setWarningState] = useState<WarningState>({
		hasBeenSeen: false,
		openWarning: false,
	})

	const form = useForm<CustomInputForm>({
		defaultValues: {
			inputs: {},
			instanceName: '',
		},
	})
	const [isTaskResolved, setIsTaskResolved] = useState(!task?.unblock_task)

	const [isResolveFlaggedTaskModalOpen, setIsResolveFlaggedTaskModalOpen] = useState(false)
	const listOptions = useListOptions({
		optionsWithFilter: task?.step?.complete_options ?? { options: [] },
		filterModel: task?.chain?.database_chain_logic?.filters,
	})
	const [rowIdsUpdating, setRowIdsUpdating] = useState<string[]>([])

	const checkCanPassTimeWarning = (): boolean => {
		const optionWithRecommendedTime = selectedOptions.find((option) => option.dueTime)
		if (optionWithRecommendedTime?.dueTime) {
			const [recommendedTimeNumber, recommendedTimeFormat] = [
				Number(optionWithRecommendedTime.dueTime.time[0]),
				optionWithRecommendedTime.dueTime.time[1] as ManipulateType,
			]
			const HasExtraTime =
				!!task &&
				dayjs
					.unix(task.created_at)
					.add(recommendedTimeNumber, recommendedTimeFormat)
					.isAfter(dayjs())
			if (HasExtraTime && !warningState.hasBeenSeen) {
				setWarningState({
					openWarning: true,
					hasBeenSeen: false,
					recommendedTimeNumber,
					recommendedTimeFormat,
					when: optionWithRecommendedTime.dueTime.when,
				})
				return false
			}
		}
		return true
	}

	const handlePrecompleteTask = (): 'continue' | 'cancel' => {
		const canPassTimeWarning = checkCanPassTimeWarning()
		if (!canPassTimeWarning) {
			return 'cancel'
		}

		if (task?.unblock_task) {
			setIsResolveFlaggedTaskModalOpen(true)
			return 'cancel'
		}

		if (onPreComplete && onPreComplete() === 'cancel') {
			return 'cancel'
		}

		return 'continue'
	}

	return (
		<FormProvider {...form}>
			<RemoveFlaggedTaskModal
				isOpen={isResolveFlaggedTaskModalOpen}
				blockedTaskName={task?.unblock_task?.name}
				onClose={() => setIsResolveFlaggedTaskModalOpen(false)}
				onComplete={() => setIsResolveFlaggedTaskModalOpen(false)}
				onCompleteAndResolve={() => {
					setIsTaskResolved(true)
					setIsResolveFlaggedTaskModalOpen(false)
				}}
			/>
			<TimeWarningModal setWarningState={setWarningState} warningState={warningState} />
			<Box display="flex" alignItems="center" flexDirection={{ mobile: 'column', laptop: 'row' }}>
				<Box>
					{!!listOptions.length && <OptionsSelect onChangeRowIdsUpdating={setRowIdsUpdating} />}
					<DatabaseModInputs
						rowIdsUpdating={rowIdsUpdating}
						disabled={!!listOptions.length && !selectedOptions.length}
					/>
					{task?.isMultiUse && !listOptions.length && (
						<Controller
							control={form.control}
							name="instanceName"
							render={({ field: { value, onChange } }) => (
								<TextField
									label="Name"
									value={value}
									onChange={onChange}
									fullWidth
									containerProps={{ width: 300 }}
									sx={{ mb: 1, mr: 1 }}
								/>
							)}
						/>
					)}
				</Box>
				<CompleteCta
					isResolved={isTaskResolved}
					onPreComplete={handlePrecompleteTask}
					onPostComplete={onPostComplete}
				/>
			</Box>
		</FormProvider>
	)
}
