import { V3BlueprintTypes } from '@cango-app/types'
import React, { ComponentType, useContext } from 'react'

import { Box, FormCard } from 'src/components'
import { getActionLabel } from 'src/helpers/labels'
import { TaskContext } from 'src/providers'
import { TaskAttachments } from 'src/modules/projects-v3/project-detail/task-drawer/configure-task/task-attachments'

import { ActionComponent } from './action-component'

export const TaskAction: ComponentType = () => {
	const { task } = useContext(TaskContext)

	if (!task) return null

	return (
		<Box display="flex" justifyContent="center" mb={2}>
			<Box width="100%" pt={3} minHeight={100}>
				{/* Render only attachments in case there is no actions */}
				{task.actions.length === 0 && !!task.attachments.length && (
					<TaskAttachments attachments={task.attachments} />
				)}
				{/* ===== Action goes below here ===== */}
				{task.actions.map((action) => {
					if (action.type === V3BlueprintTypes.ActionEnum.None) {
						return null
					}
					return (
						<FormCard key={action._id} title={getActionLabel(action.type)} mb={2}>
							<ActionComponent action={action} />
							<TaskAttachments attachments={task.attachments ?? []} />
						</FormCard>
					)
				})}
			</Box>
		</Box>
	)
}
