import React, { ComponentType, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, Modal, Select, Text, TextField } from 'src/components'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'

const NewChainModal: ComponentType<{
	selectedChainId: string | undefined
	onClose: () => void
	currentChainId: string | undefined
}> = ({ selectedChainId, onClose, currentChainId }) => {
	const dispatch = useDispatch()
	const project = useSelector(projectSelectors.getSelectedProject)
	const projectWideChains = useSelector(projectSelectors.getProjectWideChains)
	const [instanceName, setInstanceName] = useState('')
	const [isLoading, setIsLoading] = useState(false)

	const selectedChain = useMemo(() => {
		return projectWideChains.find((chain) => chain._id === selectedChainId)
	}, [selectedChainId])

	const handleClose = () => {
		setInstanceName('')
		onClose()
	}

	const handleCreateChain = async () => {
		if (!project || !selectedChainId) return
		setIsLoading(true)
		await dispatch(
			projectActions.createOnDemandChain({
				projectId: project._id,
				newChainId: selectedChainId,
				chainId: currentChainId,
				instanceName,
			}),
		)
		setIsLoading(false)
		handleClose()
	}

	return (
		<Modal open={!!selectedChain} onClose={handleClose}>
			<Box display="flex" flexDirection="column" alignItems="center">
				<Text mb={2}>{selectedChain?.label}</Text>
				<TextField
					value={instanceName}
					onChange={(event) => setInstanceName(event.target.value)}
					label="New chain name"
					sx={{ marginBottom: 2 }}
					containerProps={{ width: 300 }}
					fullWidth
					helperText="This chain will inherit all the parent chains from where it is selected"
				/>
				<Button
					disabled={!instanceName}
					sx={{ mb: 2 }}
					onClick={handleCreateChain}
					isLoading={isLoading}
				>
					Create chain
				</Button>
			</Box>
		</Modal>
	)
}

export const OnDemandChainSelection: ComponentType<{ chainId?: string }> = ({ chainId }) => {
	const projectWideChains = useSelector(projectSelectors.getProjectWideChains)
	const [selectedAsRequiredChain, setSelectedAsRequiredChain] = useState<string | undefined>()

	return (
		<>
			<NewChainModal
				selectedChainId={selectedAsRequiredChain}
				onClose={() => setSelectedAsRequiredChain(undefined)}
				currentChainId={chainId}
			/>
			<Select
				options={projectWideChains ?? []}
				containerProps={{ width: 200 }}
				label="On-demand chains"
				value=""
				onChange={(event) => setSelectedAsRequiredChain(event.target.value as string)}
			/>
		</>
	)
}
