import { V3ClientTypes, V3ProjectTypes } from '@cango-app/types'
import { ComponentType, useMemo, useState, useContext } from 'react'
import dayjs from 'dayjs'
import { PulseLoader } from 'react-spinners'

import { Box, Divider, DriveUploadButton, Select, Text } from 'src/components'
import { colors } from 'src/theme/colors'
import { TaskContext } from 'src/providers'

import { TaskFiles } from './task-files'
import { CompletationInfo } from './completation-info'

type FileViewProject = Pick<V3ClientTypes.Project.Project, 'name' | 'google_drive_folder_id'>

export type FileViewItemType = {
	stepId: string
	taskName: string
	chain: V3ProjectTypes.ProjectChain | undefined
	tasks: V3ClientTypes.Project.FileFromTask[]
}

export const FileViewItem: ComponentType<{
	item: FileViewItemType
	project: FileViewProject
	onUpdateFileIds: (taskId: string, fileIds: string[], actionIndex: number) => Promise<void>
	isLastItem: boolean
}> = ({ item, onUpdateFileIds, isLastItem }) => {
	const { isLoadingTask } = useContext(TaskContext)
	const [selectedTaskId, setSelectedTaskId] = useState<string>(item.tasks[0]._id)

	const selectedTask = useMemo(() => {
		return item.tasks.find((_task) => _task._id === selectedTaskId)
	}, [selectedTaskId, item])

	const completedAtTime = selectedTask?.lifecycle?.completed_meta?.length
		? selectedTask.lifecycle?.completed_meta[selectedTask.lifecycle?.completed_meta.length - 1]
		: undefined

	const taskLabel = selectedTask?.chain?._id ? (
		<>
			{item.chain?.label.prefix}: <b>{item.chain?.label.selected_option}</b>
		</>
	) : undefined

	const options = useMemo(() => {
		if (item.tasks.length === 1) return []

		return item.tasks.map((_task) => {
			const _taskCompletedTime = _task.lifecycle?.completed_meta?.length
				? _task.lifecycle?.completed_meta[_task.lifecycle?.completed_meta.length - 1]
				: undefined
			return {
				_id: _task._id,
				label: (
					<Box>
						<Text fontWeight="bold" fontSize={12}>
							Iteration {_task.iteration}
						</Text>
						{!!_taskCompletedTime && (
							<Text fontSize={12}>
								{dayjs.unix(_taskCompletedTime.at).format('MMM DD, YYYY hh:mma')}
							</Text>
						)}
					</Box>
				),
			}
		})
	}, [])

	if (!selectedTask?._id && isLoadingTask) {
		return (
			<Box>
				<PulseLoader size={6} />
			</Box>
		)
	}

	if (!selectedTask?._id) return null

	if (selectedTask.actions.every((_action) => !_action.file_ids.length)) {
		return null
	}

	return (
		<Box key={`add-files-for-${selectedTask._id}`} mb={1}>
			<Box mb={1}>
				<Text fontSize={14}>
					Task: <b>{item.taskName}</b>
				</Text>
				<Text fontSize={14}>{!!selectedTask.chain?._id && <>{taskLabel}</>}</Text>
				{options.length ? (
					<Select
						options={options}
						value={selectedTask._id}
						onChange={(e) => setSelectedTaskId(e.target.value as string)}
						containerProps={{ sx: { mt: 1 } }}
					/>
				) : (
					<>
						{(selectedTask.iteration ?? 0) > 1 && (
							<Text fontSize={14}>
								Iteration: <b>{selectedTask.iteration}</b>
							</Text>
						)}
						<CompletationInfo completedAtTime={completedAtTime?.at} />
					</>
				)}
			</Box>
			{selectedTask.actions.map((_action, actionIndex) => {
				const actionFileIds = _action.file_ids ?? []
				return (
					<Box key={_action._id} mb={1}>
						<Box mb={1} display="flex" justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
							<DriveUploadButton
								selectedFilesIds={actionFileIds}
								ctaVariant={actionFileIds.length ? 'replaceIcon' : 'button'}
								onFileIdsChange={(fileIds: string[]) =>
									onUpdateFileIds(selectedTask._id, fileIds, actionIndex)
								}
							/>
						</Box>
						<TaskFiles action={_action} actionIndex={actionIndex} />
					</Box>
				)
			})}
			{!isLastItem && <Divider color={colors.neutral['60']} sx={{ my: 2 }} />}
		</Box>
	)
}
