import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { useContext, useMemo } from 'react'
import { Stack } from '@mui/material'

import { getActionLabel } from 'src/helpers/labels'
import { TaskContext } from 'src/providers'
import { Grid, Text } from 'src/components'
import { ActionComponent } from 'src/modules/my-tasks-v3/components/actions/action-component'

type Props = {
	attachments: V3ClientTypes.Project.Task['attachments']
}

const groupAttachmentsByRows = (attachments: V3ClientTypes.Project.Task['attachments']) => {
	const orderedRows: Array<V3ClientTypes.Project.TaskAction & { width: number }> = []

	let currentWidth = 0

	attachments.forEach((attachment) => {
		if (attachment.type === V3BlueprintTypes.ActionEnum.None) {
			return
		}
		const width = attachment.type === V3BlueprintTypes.ActionEnum.SetResources ? 1 : 0.5

		if (currentWidth + width <= 1) {
			orderedRows.push({ ...attachment, width })
			currentWidth += width
		} else {
			currentWidth = width // Start new row width with the current attachment
			orderedRows.push({ ...attachment, width })
		}
	})

	return orderedRows
}

export const TaskAttachments = ({ attachments: unorderedAttachments }: Props) => {
	const { task } = useContext(TaskContext)

	const attachments = useMemo(() => {
		return groupAttachmentsByRows(unorderedAttachments)
	}, [unorderedAttachments])

	if (!task || !attachments.length) {
		return null
	}

	return (
		<Grid container mt={3} gap={1} wrap="wrap">
			{attachments.map((attachment) => {
				if (attachment.type === V3BlueprintTypes.ActionEnum.None) {
					return null
				}
				return (
					<Grid
						item
						key={attachment._id}
						flex={`${attachment.width * 100}%`}
						sx={{
							display: 'flex',
							flexDirection: 'column',
							width: '100%',
						}}
					>
						<Stack
							spacing={2}
							direction="column"
							p={2}
							sx={{
								boxShadow: 'rgb(0 0 0 / 12%) 2px 4px 15px;',
								borderRadius: '20px 0px 20px 20px',
								minHeight: '150px',
								height: '100%',
								display: 'flex',
							}}
						>
							<Text
								variant="overline"
								fontWeight="bold"
							>{`Attachment-${getActionLabel(attachment.type)}`}</Text>
							<ActionComponent action={attachment} isAttachment />
						</Stack>
					</Grid>
				)
			})}
		</Grid>
	)
}
