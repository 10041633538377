import React, { ComponentType, useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { MyTasksV3Sdk } from '@cango-app/sdk'

import { Box, Text, Button, Modal, Select, TextField } from 'src/components'
import { selectors as userSelectors, actions as userActions } from 'src/store/modules/user'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { colors } from 'src/theme/colors'
import { GENERIC_BLOCKED_REASONS } from 'src/constants/options'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { TaskContext } from 'src/providers'

type IssueForm = {
	reportTo?: string
	reason: MyTasksV3Sdk.BlockedTaskReason
	description: string
}

type ReportBlockedTaskModalProps = {
	open: boolean
	onClose: () => void
}

export const ReportBlockedTaskModal: ComponentType<ReportBlockedTaskModalProps> = ({
	open,
	onClose,
}) => {
	const dispatch = useDispatch()
	const { selectedProjectId } = useMyTasks()
	const { task: selectedTask } = useContext(TaskContext)
	const usersForSelect = useSelector(userSelectors.getAllUsersForSelect)
	const blockOptions = useSelector(projectSelectors.getBlockOptions)
	const isLoadingBlockOptions = useSelector(projectSelectors.getIsLoadingBlockOptions)
	const { control, handleSubmit, watch, reset, setValue } = useForm<IssueForm>()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [description, reportTo, reason] = watch(['description', 'reportTo', 'reason'])
	const isGenericReason = GENERIC_BLOCKED_REASONS.includes(reason)
	useEffect(() => {
		if (!isGenericReason) {
			setValue('reportTo', undefined)
		}
	}, [isGenericReason, setValue])

	const handleCloseModal = () => {
		reset()
		onClose()
	}

	const onSubmitForm = async (data: IssueForm) => {
		if (!selectedTask || !data.description) return
		setIsSubmitting(true)
		await dispatch(
			projectActions.flagTask({
				taskId: selectedTask._id,
				reportTo: data.reportTo || '',
				description: data.description,
				reason: data.reason,
			}),
		)
		setIsSubmitting(false)
		reset()
		handleCloseModal()
	}

	const isSubmitDisabled = isGenericReason
		? ![description, reportTo, reason].every(Boolean)
		: ![description, reason].every(Boolean)

	useEffect(() => {
		if (selectedTask && !usersForSelect.length) {
			dispatch(userActions.fetchAllUsers())
		}
	}, [selectedTask, JSON.stringify(usersForSelect)])

	useEffect(() => {
		if (selectedProjectId) {
			dispatch(projectActions.fetchBlockOptions(selectedProjectId))
		}
	}, [selectedProjectId])

	return (
		<Modal
			open={open}
			onClose={handleCloseModal}
			containerStyle={{ width: 670, maxWidth: '100%', bgcolor: colors.background.modal }}
		>
			<Box p={2} flex={1}>
				{!!selectedTask && (
					<>
						<Text fontSize={18} fontWeight={500} mb={2}>
							Report this blocked task
						</Text>
						<Box display="flex" sx={{ flexDirection: { laptop: 'row', mobile: 'column' } }} mb={2}>
							<Controller
								control={control}
								name="reason"
								rules={{ required: true }}
								render={({ field: { onChange, value } }) => (
									<Select
										label="Why are you blocked?"
										options={blockOptions}
										isLoading={isLoadingBlockOptions}
										containerProps={{ flex: 1, mr: { laptop: 0.5, mobile: 0 } }}
										onChange={onChange}
										value={value}
									/>
								)}
							/>
							{isGenericReason || !reason ? (
								<Controller
									control={control}
									name="reportTo"
									rules={{ required: false }}
									render={({ field: { onChange, value } }) => (
										<Select
											label="Report to"
											options={usersForSelect}
											containerProps={{ flex: 1, ml: { laptop: 0.5, mobile: 0 } }}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
							) : (
								<Box flex={1} ml={{ laptop: 0.5, mobile: 0 }}>
									<Text fontSize={14} color={colors.neutral['80']} fontWeight={500} mb={1}>
										Report to
									</Text>
									<Text>Task owner</Text>
								</Box>
							)}
						</Box>
						<Controller
							control={control}
							name="description"
							rules={{ required: true }}
							render={({ field: { onChange, value } }) => (
								<TextField
									multiline
									label="Description"
									fullWidth
									onChange={onChange}
									value={value}
									helperText="They'll receive an email, the task will be flagged, and the description will be added to the task inbox"
									containerProps={{ mb: 2 }}
									FormHelperTextProps={{ sx: { ml: 0 } }}
								/>
							)}
						/>
						<Box display="flex" flexDirection={{ mobile: 'column', laptop: 'row' }}>
							<Button
								onClick={handleCloseModal}
								variant="text"
								sx={{ mr: { laptop: 1 }, mb: { mobile: 1, laptop: 0 }, flex: 1 }}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								sx={{ ml: { laptop: 1 }, flex: 1 }}
								onClick={handleSubmit(onSubmitForm)}
								disabled={isSubmitDisabled}
								isLoading={isSubmitting}
							>
								Submit
							</Button>
						</Box>
					</>
				)}
			</Box>
		</Modal>
	)
}
