import { V3ClientTypes, V3ServerTypes, V3ProjectTypes } from '@cango-app/types'
import _cloneDeep from 'lodash/cloneDeep'
import dayjs from 'dayjs'

export const completeActiveTask = ({
	activeTask,
	completed_options,
	userId,
}: {
	activeTask: V3ClientTypes.Project.Task
	completed_options: V3ProjectTypes.CompletedOption[]
	userId: string
}): {
	updatedTask: V3ClientTypes.Project.Task
	taskUpdates: Partial<V3ServerTypes.Project.Task>
} => {
	const taskUpdates: Partial<Pick<V3ServerTypes.Project.Task, 'lifecycle' | 'isFlagged'>> = {}

	const lifeCycle: V3ServerTypes.Project.Task['lifecycle'] = {
		...activeTask.lifecycle,
		completed_meta: [
			...(activeTask.lifecycle.completed_meta ?? []),
			{
				at: dayjs().unix(),
				by: userId,
			},
		],
		completed_options: [...(activeTask.lifecycle.completed_options ?? []), ...completed_options],
		...(activeTask.isMultiUse ? {} : { complete: true }),
	}

	taskUpdates.isFlagged = false
	taskUpdates.lifecycle = lifeCycle

	const updatedTask = {
		..._cloneDeep(activeTask),
		...taskUpdates,
	}

	return {
		updatedTask,
		taskUpdates,
	}
}
