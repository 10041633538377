/**
 * Blueprint Builder-Manager
 *
 * @returns ComponentType
 */

import React, { ComponentType } from 'react'
import { useParams } from 'react-router-dom'

import { ChainProvider } from 'src/modules/chains/chain-provider'

import { ChainFlow } from '../../chains/chain-flow'

export const BlueprintContainer: ComponentType = () => {
	const { blueprintId } = useParams<{
		blueprintId: string
	}>()

	return (
		<ChainProvider chainId={blueprintId}>
			<ChainFlow />
		</ChainProvider>
	)
}
