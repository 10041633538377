import { ComponentType, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { RootState } from 'src/store/types'

import TaskListItem from './task-list-item'

export const TaskList: ComponentType = () => {
	const { selectedSectionId, goToTask } = useMyTasks()
	const section = useSelector((state: RootState) =>
		projectSelectors.getProjectSection(state, selectedSectionId),
	)

	const incompleteTasks = section?.tasks.filter((_task) => !_task.lifecycle.complete) ?? []

	useEffect(() => {
		if (section && incompleteTasks.length === 1) {
			goToTask(incompleteTasks[0])
		}
	}, [section, incompleteTasks])

	if (!section) {
		return null
	}

	return (
		<>
			{incompleteTasks.map((task, index) => {
				return (
					<TaskListItem
						key={task._id}
						task={task}
						isFirstTask={index === 0}
						isLastTask={index === section.tasks.length - 1}
						onClick={() => goToTask(task)}
					/>
				)
			})}
		</>
	)
}
