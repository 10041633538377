import { ComponentType, useContext, useState } from 'react'
import { Handle, NodeProps, Position } from 'reactflow'
import Stack from '@mui/system/Stack/Stack'
import DoneAllIcon from '@mui/icons-material/DoneAll'

import { Box, Button, Modal, Text } from 'src/components'
import { ChainCompleteNodeData } from 'src/modules/chains/types'
import { ChainContext } from 'src/modules/chains/chain-provider'

export const ChainCompleteNode: ComponentType<NodeProps<ChainCompleteNodeData>> = ({ data }) => {
	const { onUpdateConnection } = useContext(ChainContext)
	const [settingsOpen, setSettingsOpen] = useState(false)

	const handleDeleteConnection = async () => {
		await onUpdateConnection({
			_id: data.descendant._id,
			connection: { source: data.source, target: '' },
			method: 'remove',
		})
	}

	return (
		<>
			{settingsOpen && (
				<Modal open={true} onClose={() => setSettingsOpen(false)}>
					<Box>
						<Button color="error" onClick={handleDeleteConnection}>
							Delete connection
						</Button>
					</Box>
				</Modal>
			)}
			<Box
				sx={{
					bgcolor: '#e5f6fd',
					px: 2,
					py: 1,
					borderRadius: 1,
				}}
				onClick={() => setSettingsOpen(true)}
			>
				<Handle type="target" position={Position.Top} isConnectable={false} />
				<Stack direction="row">
					<DoneAllIcon fontSize="small" sx={{ mr: 1 }} />
					<Text fontSize="small">Start next chain</Text>
				</Stack>
			</Box>
		</>
	)
}
