import React, { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ChainsSdk } from '@cango-app/sdk'
import { Stack } from '@mui/material'

import { Box, Button, Drawer, Text, NameInput, FormCard, Select, Checkbox } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { AnalyticsEvent, analytics } from 'src/biz'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { TableContext } from 'src/providers'

import { ChainContext } from '../../chain-provider'

import { DeleteBlueprintModal } from './delete-blueprint-modal'
import { BluepritnCtaDatabaseModal } from './blueprint-cta-database-modal'
import { EditExternalReferencesModal } from './edit-external-references-modal'

type BlueprintOpsDrawerProps = {
	open: boolean
	onClose: () => void
}

export const BlueprintOpsDrawer: ComponentType<BlueprintOpsDrawerProps> = ({
	open,
	onClose,
	// blueprintId,
	// isPublished,
	// relatedProjects,
}) => {
	const { updateChain, chain } = useContext(ChainContext)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	// const sections = useSelector(blueprintSelectors.getSections)
	// const blueprintDatabaseTable = useSelector(blueprintSelectors.getSelectedBlueprintDatabaseTable)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [openExternalReferencesModal, setOpenExternalReferencesModal] = useState(false)
	const [isLoadingChains, setIsLoadingChains] = useState(false)
	const [chainsList, setChainsList] = useState<{ _id: string; label: string }[]>([])
	const [openDatabaseCtasModal, setOpenDatabaseCtasModal] = useState(false)

	const { tableList, isFetchingTableList } = useContext(TableContext)

	const handleUpdateName = async (newName: string) => {
		const oldName = chain?.name
		await updateChain({ name: newName })
		analytics.track({
			eventName: AnalyticsEvent.blueprintNameUpdated,
			properties: {
				oldName: oldName ?? '',
				newName,
			},
		})
	}

	const fetchChains = useCallback(async () => {
		setIsLoadingChains(true)
		try {
			const response = await ChainsSdk.getAll(import.meta.env.VITE_API as string, authHeaders, {
				blueprints: 'false',
			})
			setChainsList(response.map((_chain) => ({ _id: _chain._id, label: _chain.name })))
		} catch (error) {
			showSnackbar('Failed to fetch chains', { variant: 'error' })
		} finally {
			setIsLoadingChains(false)
		}
	}, [authHeaders])

	// const handleSelectDatabaseTable = async (tableId: string) => {
	// 	setIsSavingTableSelection(true)
	// 	await dispatch(blueprintActions.updateDatabaseTable({ tableId, blueprintId }))
	// 	setIsSavingTableSelection(false)
	// }

	useEffect(() => {
		if (open) {
			fetchChains()
		}
	}, [open])

	return (
		<>
			<DeleteBlueprintModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
			{openExternalReferencesModal && (
				<EditExternalReferencesModal onClose={() => setOpenExternalReferencesModal(false)} />
			)}
			<Drawer open={open} onClose={onClose}>
				<Box width={400} p={2} display="flex" flexDirection="column">
					<Text variant="h5" mb={2}>
						Settings
					</Text>
					<FormCard title="Blueprint name" mb={2}>
						<NameInput name={chain?.name ?? ''} onUpdateClick={handleUpdateName} />
					</FormCard>
					<FormCard title="Project wide chains" mb={2}>
						<Select
							options={chainsList}
							value={!chainsList.length ? [] : (chain?.project_wide_chains ?? [])}
							multiple
							isLoading={isLoadingChains}
							onChange={(event) => {
								updateChain({ project_wide_chains: event.target.value as string[] })
							}}
							helperText="These chains will be available at all stages of a project"
						/>
						{!chain?.is_blueprint && (
							<Box mt={2}>
								<Checkbox
									label="Does this chain unblock tasks?"
									size="small"
									checked={chain?.is_blocker_type}
									onClick={() => {
										updateChain({ is_blocker_type: !chain?.is_blocker_type })
									}}
									fontSize={12}
								/>
							</Box>
						)}
					</FormCard>
					<FormCard title="Database" mb={2}>
						<Stack direction="column" spacing={2}>
							<Select
								options={tableList.map((_item) => ({ ..._item, label: _item.name }))}
								value={chain?.database_table}
								isLoading={isFetchingTableList}
								onChange={(event) => {
									updateChain({
										database_table: event.target.value as string,
									})
								}}
							/>
							<BluepritnCtaDatabaseModal
								chainList={chainsList}
								isOpen={openDatabaseCtasModal}
								onClose={() => setOpenDatabaseCtasModal(false)}
							/>
							<Button variant="outlined" onClick={() => setOpenDatabaseCtasModal(true)}>
								Add Database CTAs
							</Button>
						</Stack>
					</FormCard>
					{!chain?.is_blueprint && (
						<FormCard title="External References">
							<Button
								variant="outlined"
								fullWidth
								onClick={() => setOpenExternalReferencesModal(true)}
							>
								Edit External Task References
							</Button>
						</FormCard>
					)}
					<FormCard title="Danger zone">
						<Button
							color="error"
							size="small"
							variant="outlined"
							fullWidth
							onClick={() => setShowDeleteModal(true)}
						>
							Delete Blueprint
						</Button>
					</FormCard>
				</Box>
			</Drawer>
		</>
	)
}
