import { ChainsSdk } from '@cango-app/sdk'
import { ChainTypes } from '@cango-app/types'
import { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Stack } from '@mui/material'

import { Box, Checkbox, Select, Text } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { ChainContext } from 'src/modules/chains/chain-provider'

import { StepFormType } from './step-form-container'

export const ChainReferenceForm: ComponentType<{
	onDeselectChainReference: () => void
	stepId: string
}> = ({ onDeselectChainReference, stepId }) => {
	const { control, setValue, watch } = useFormContext<StepFormType>()
	const [chains, setChains] = useState<{ _id: string; label: string }[]>([])
	const [externalReferences, setExternalReferences] = useState<
		Record<string, ChainTypes.ChainExternalReference[]>
	>({})
	const [isLoadingChains, setIsLoadingChains] = useState(false)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const currenctChainId = watch('chain_id')
	const chainReference = watch('chain_reference')

	const { nodes } = useContext(ChainContext)
	const taskOptions = useMemo(() => {
		const filteredNodes = nodes.filter((_node) => _node.id !== stepId && !_node.data.isSection)
		return filteredNodes.map((_node) => ({
			_id: _node.id,
			label: _node.data.name,
		}))
	}, [nodes])

	const fetchChains = useCallback(async () => {
		setIsLoadingChains(true)
		try {
			const response = await ChainsSdk.getAll(import.meta.env.VITE_API as string, authHeaders, {
				blueprints: 'false',
			})

			const chainOptions = response.reduce((_acc: { _id: string; label: string }[], chain) => {
				if (chain._id === currenctChainId) return _acc
				return [..._acc, { _id: chain._id, label: chain.name }]
			}, [])
			const references = response.reduce(
				(_acc: Record<string, ChainTypes.ChainExternalReference[]>, chain) => {
					if (chain.external_references?.length) {
						_acc[chain._id] = chain.external_references
					}
					return _acc
				},
				{},
			)
			setChains(chainOptions)
			setExternalReferences(references)
		} catch (error) {
			showSnackbar('Error fetching chains', { variant: 'error' })
			Sentry.captureException(error)
		} finally {
			setIsLoadingChains(false)
		}
	}, [])

	const handleUncheckChainsReference = () => {
		setValue('chain_reference', '', { shouldDirty: true })
		onDeselectChainReference()
	}

	useEffect(() => {
		fetchChains()
	}, [])

	const selectedExternalReferences = useMemo(() => {
		return externalReferences[chainReference]
	}, [chainReference, externalReferences])

	return (
		<Box>
			<Checkbox
				label={'This references another chain'}
				checked
				onChange={handleUncheckChainsReference}
			/>
			<Controller
				control={control}
				name="chain_reference"
				render={({ field: { value, onChange } }) => (
					<Select
						label="Which chain should start here?"
						options={chains}
						value={value}
						onChange={onChange}
						isLoading={isLoadingChains}
					/>
				)}
			/>
			{selectedExternalReferences && !!selectedExternalReferences.length && (
				<Stack direction="column" spacing={1} mt={3}>
					<Text variant="h6">External References</Text>
					<Controller
						control={control}
						name="external_references"
						render={({ field: { value: references, onChange } }) => (
							<>
								{selectedExternalReferences.map((externalReference) => {
									return (
										<Select
											label={externalReference.label}
											key={externalReference._id}
											options={taskOptions}
											value={
												references?.find((reference) => reference._id === externalReference._id)
													?.step
											}
											onChange={(event) => {
												const newExternalReferences = selectedExternalReferences.map(
													(reference) => {
														if (reference._id === externalReference._id) {
															return {
																_id: reference._id,
																step: event.target.value as string,
															}
														}
														const foundWatchExternalReferences = references.find(
															({ _id }) => _id === reference._id,
														)
														if (foundWatchExternalReferences) {
															return foundWatchExternalReferences
														}
														return {
															_id: reference._id,
														}
													},
												)
												onChange(newExternalReferences)
											}}
										/>
									)
								})}
							</>
						)}
					/>
				</Stack>
			)}
		</Box>
	)
}
