import React, { ComponentType, useCallback, useContext, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TableTypes, V3BlueprintTypes } from '@cango-app/types'

import { TableContext, TaskContext } from 'src/providers'
import { Box, TextField } from 'src/components'
import { CustomInputForm } from 'src/modules/my-tasks-v3/components/complete-task-cta/types'
import useDebouncedCallback from 'src/hooks/useDebouncedCallback'
import { UpdateRecordsRequestParams } from 'src/providers/table-provider/types'

const DatabaseModInput: ComponentType<{
	change: V3BlueprintTypes.DatabaseChangeItem
	rowIdsUpdating: string[]
	disabled: boolean
}> = ({ change, rowIdsUpdating, disabled }) => {
	const { control, setValue } = useFormContext<CustomInputForm>()
	const { mappedColumns, mappedRecords, updateRecords } = useContext(TableContext)

	const debouncedOnUpdateFields = useDebouncedCallback(
		useCallback(
			(data: UpdateRecordsRequestParams) => {
				updateRecords(data)
			},
			[updateRecords],
		),
		300,
	)

	const handleCustomFieldChange = useCallback(
		(columnId: string, value: string) => {
			const updatedFields = rowIdsUpdating.reduce(
				(
					acc: {
						newRecord: TableTypes.Record
						oldRecord: TableTypes.Record
					}[],
					_rowId,
				) => {
					const originalRecord = mappedRecords.get(_rowId)
					if (!originalRecord) {
						return acc
					}
					const updatedRecord = { ...originalRecord }
					const column = mappedColumns.get(columnId)
					if (!column) {
						return acc
					}
					if (column.type === TableTypes.FieldType.CALCULATION) {
						updatedRecord.overrides = {
							...updatedRecord.overrides,
							[columnId]: value,
						}
					} else {
						updatedRecord.data[columnId] = value
					}
					return [
						...acc,
						{
							newRecord: updatedRecord,
							oldRecord: originalRecord,
						},
					]
				},
				[],
			)
			setValue(`inputs.${columnId}`, value)
			debouncedOnUpdateFields({
				rows: updatedFields,
			})
		},
		[rowIdsUpdating, mappedRecords, mappedColumns, debouncedOnUpdateFields],
	)

	return (
		<Controller
			control={control}
			rules={{ required: change.is_required }}
			name={`inputs.${change.field}`}
			render={({ field: { value }, fieldState: { error } }) => (
				<TextField
					label={mappedColumns.get(change.field)?.name}
					value={value}
					onChange={(e) => {
						handleCustomFieldChange(change.field, e.target.value)
					}}
					containerProps={{ width: 300 }}
					fullWidth
					disabled={disabled}
					error={!!error}
					helperText={error?.message}
				/>
			)}
		/>
	)
}

export const DatabaseModInputs: ComponentType<{ rowIdsUpdating: string[]; disabled: boolean }> = ({
	rowIdsUpdating,
	disabled,
}) => {
	const { descendants } = useContext(TaskContext)

	const hasDatabaseMods = useMemo(
		() => descendants.some(({ database_changes }) => !!database_changes),
		[descendants],
	)

	if (!hasDatabaseMods) {
		return null
	}

	return (
		<Box>
			{descendants.map((_desc) => {
				if (!_desc.database_changes) {
					return null
				}
				return (
					<Box key={_desc._id}>
						{_desc.database_changes.map((_change) => {
							if (_change.operator !== 'custom_input') {
								return null
							}
							return (
								<DatabaseModInput
									key={_change.id}
									change={_change}
									rowIdsUpdating={rowIdsUpdating}
									disabled={disabled}
								/>
							)
						})}
					</Box>
				)
			})}
		</Box>
	)
}
