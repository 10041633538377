import { V3ClientTypes, V3ProjectTypes, V3ServerTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import _uniqBy from 'lodash/uniqBy'

import {
	createInstanceChain,
	getNewChainAfterEnding,
} from 'src/modules/my-tasks-v3/components/complete-task-cta/utils'
import { CompletingTask } from 'src/modules/my-tasks-v3/components/complete-task-cta/types'

const createChain = ({
	completingTask,
	descendant,
	instanceName,
}: {
	completingTask: CompletingTask
	descendant: V3ClientTypes.Project.ProjectDescendant
	instanceName: string | undefined
}):
	| { mainChain?: V3ProjectTypes.ProjectChain; instanceChain?: V3ProjectTypes.ProjectChain }
	| undefined => {
	const parentChains = completingTask?.chain?.parent_chains ?? []
	let instanceChain: V3ProjectTypes.ProjectChain | undefined
	if (instanceName) {
		instanceChain = createInstanceChain(instanceName, parentChains)
	}

	let parentTaskChain = completingTask?.chain
	if (!parentTaskChain?._id && !instanceName) {
		return undefined
	}

	if (!parentTaskChain && instanceChain) {
		parentTaskChain = instanceChain
	} else if (instanceChain) {
		parentChains.push(instanceChain)
	}

	if (!parentTaskChain) {
		return undefined
	}

	return {
		mainChain: getNewChainAfterEnding({
			projectChain: parentTaskChain,
			parentChains,
			descendant,
		}),
		instanceChain,
	}
}

const createUnlinkedTaskWithChain = ({
	descendant,
	completingTask,
	instanceName,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	completingTask: CompletingTask
	instanceName: string | undefined
}): { tasks: V3ServerTypes.Project.Task[]; chains: V3ProjectTypes.ProjectChain[] } => {
	const blueprintStep = descendant.step
	if (!blueprintStep) {
		return {
			tasks: [],
			chains: [],
		}
	}
	const chain = createChain({
		descendant,
		completingTask,
		instanceName,
	})

	const newChains = chain?.mainChain
		? [chain.mainChain, ...(chain?.instanceChain ? [chain.instanceChain] : [])]
		: []

	return {
		chains: _uniqBy(newChains, '_id'),
		tasks: [
			{
				...blueprintStep,
				_id: v4(),
				chain: chain?.mainChain,
				step: blueprintStep._id,
				project_id: completingTask.project_id,
				notes: [],
				annotations: [],
				parent: completingTask._id,
				assignees: [],
				lifecycle: {
					complete: false,
					completed_meta: [],
					completed_options: [],
				},
				unblock_task: undefined,
				section: completingTask.section,
				iteration: 1,
				actions: [],
				attachments: [],
				reference_group: completingTask.reference_group,
			},
		],
	}
}

export const createRegularTasks = ({
	descendant,
	completingTask,
	instanceName,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	completingTask: CompletingTask
	instanceName: string | undefined
}): { tasks: V3ServerTypes.Project.Task[]; chains: V3ProjectTypes.ProjectChain[] } => {
	return createUnlinkedTaskWithChain({
		descendant,
		completingTask,
		instanceName,
	})
}
