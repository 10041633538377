import { TableTypes, V3BlueprintTypes } from '@cango-app/types'
import { useContext, useMemo } from 'react'
import _isArray from 'lodash/isArray'
import { GridFilterModel } from '@mui/x-data-grid-premium'
import _isString from 'lodash/isString'

import { getFieldType } from 'src/modules/tables/mui-formatter'
import { TableContext } from 'src/providers'
import { MappedValueOptions, ResolvedRowData } from 'src/providers/table-provider/types'

import { applyFilterModelToRow, columnValueGetter } from '../modules/tables/utils'

export const getListFromTable = ({
	optionsWithFilter,
	mappedColumns,
	resolvedRows,
	filterModel = {
		items: [],
	},
	mappedValueOptions,
	isBlueprint,
}: {
	optionsWithFilter?: V3BlueprintTypes.StepOptionsWithFilter
	mappedColumns: Map<string, TableTypes.Field>
	resolvedRows: ResolvedRowData[]
	filterModel?: GridFilterModel
	mappedValueOptions: MappedValueOptions
	isBlueprint?: boolean
}): {
	_id: string
	label: string
}[] => {
	if (!_isString(optionsWithFilter?.options)) {
		return []
	}

	const columnId = optionsWithFilter.options

	const field = mappedColumns.get(columnId)

	if (!field) {
		return []
	}

	const filterModelWithOptionFilters: GridFilterModel = {
		...(!isBlueprint ? [optionsWithFilter.filter] : []),
		items: [
			...(filterModel.items ?? []),
			...(!isBlueprint && optionsWithFilter.filter?.items ? optionsWithFilter.filter.items : []),
		],
	}

	return (
		resolvedRows.reduce((_reducedOptions: { _id: string; label: string }[], _record) => {
			const resolvedRow = resolvedRows.find((row) => row._id === _record._id)
			if (!resolvedRow) {
				return _reducedOptions
			}

			const value = columnValueGetter(resolvedRow[columnId], field, mappedValueOptions)
			const valueOptionId = field.valueOptions.find((option) => option.label === value)?._id

			if (filterModelWithOptionFilters.items.length) {
				const isFiltered = applyFilterModelToRow({
					columns: [...mappedColumns.values()].map(({ _id, type, returnType }) => ({
						_id,
						type: getFieldType(type, returnType),
					})),
					filterModel: filterModelWithOptionFilters,
					row: resolvedRow,
				})

				if (!isFiltered) {
					return _reducedOptions
				}
			}

			if (_reducedOptions.some((_reducedOption) => _reducedOption.label === value)) {
				return _reducedOptions
			}

			if (!value) {
				return _reducedOptions
			}

			return [
				..._reducedOptions,
				{
					_id: valueOptionId ?? _record._id,
					label: String(value),
					originalValue: resolvedRow[columnId],
				},
			]
		}, []) ?? []
	)
}

export const useListOptions = ({
	optionsWithFilter,
	filterModel,
	isBlueprint,
}: {
	optionsWithFilter: V3BlueprintTypes.StepOptionsWithFilter
	filterModel?: GridFilterModel
	isBlueprint?: boolean
}): {
	_id: string
	label: string
	dueTime?: {
		time: string
		when: V3BlueprintTypes.WhenDueTime
	}
	originalValue?: TableTypes.RecordDataItem
}[] => {
	const { mappedColumns, resolvedRows, mappedValueOptions } = useContext(TableContext)
	return useMemo(() => {
		if (!optionsWithFilter) {
			return []
		}

		if (_isArray(optionsWithFilter.options)) {
			return optionsWithFilter.options
		}

		if (!resolvedRows.length) {
			return []
		}

		return getListFromTable({
			optionsWithFilter,
			mappedColumns,
			resolvedRows,
			filterModel,
			mappedValueOptions,
			isBlueprint,
		})
	}, [optionsWithFilter, mappedColumns, filterModel, resolvedRows, isBlueprint])
}
