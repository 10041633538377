import React, { ComponentType, useContext } from 'react'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'

import { TaskContext } from 'src/providers'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { FileTemplate } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-template'
import { Archive } from 'src/modules/my-tasks-v3/components/actions/archive'
import { ContactInfo } from 'src/modules/my-tasks-v3/components/actions/contact-info'
import { CreateContact } from 'src/modules/my-tasks-v3/components/actions/create-contact'
import { FileUpload } from 'src/modules/my-tasks-v3/components/actions/file-tasks/file-upload'
import { Note } from 'src/modules/my-tasks-v3/components/actions/note'
import { Software } from 'src/modules/my-tasks-v3/components/actions/software'
import { VideoEmbed } from 'src/modules/my-tasks-v3/components/actions/video-embed'
import { SetResources } from 'src/modules/my-tasks-v3/components/actions/set-resources'
import { TaskReference } from 'src/modules/my-tasks-v3/components/actions/task-reference'
import { Template } from 'src/modules/my-tasks-v3/components/actions/template-modal'
import { QuestionFlowLogicContainer } from 'src/components/question-flow/question-flow-logic-container'

import { AttachmentFileTemplate } from '../attachments/file-template'

export const ActionComponent: ComponentType<{
	action: V3ClientTypes.Project.TaskAction
	isAttachment?: boolean
}> = ({ action, isAttachment = false }) => {
	const { task } = useContext(TaskContext)
	const project = useSelector(projectSelectors.getSelectedProject)
	if (!task || !project) return null

	switch (action.type) {
		case V3BlueprintTypes.ActionEnum.FileTemplate:
			return isAttachment ? (
				<AttachmentFileTemplate task={task} action={action} project={project} />
			) : (
				<FileTemplate task={task} project={project} action={action} />
			)
		case V3BlueprintTypes.ActionEnum.Archive:
			return <Archive task={task} />
		case V3BlueprintTypes.ActionEnum.Invite:
		case V3BlueprintTypes.ActionEnum.Meeting:
		case V3BlueprintTypes.ActionEnum.Call:
		case V3BlueprintTypes.ActionEnum.Email:
			return <ContactInfo project={project} />
		case V3BlueprintTypes.ActionEnum.Contact:
			return <CreateContact task={task} />
		case V3BlueprintTypes.ActionEnum.FileUpload:
			return <FileUpload project={project} task={task} action={action} />
		case V3BlueprintTypes.ActionEnum.Note:
			return <Note note={action.note} />
		case V3BlueprintTypes.ActionEnum.Software:
			return <Software links={action.links} />
		case V3BlueprintTypes.ActionEnum.Video:
			return <VideoEmbed urls={action.links} />
		case V3BlueprintTypes.ActionEnum.SetResources:
			return <SetResources action={action} />
		case V3BlueprintTypes.ActionEnum.TaskReference:
			return <TaskReference taskReferences={action.task_references} project={project} />
		case V3BlueprintTypes.ActionEnum.Template:
			return <Template action={action} />
		case V3BlueprintTypes.ActionEnum.Questionaire:
			return (
				<QuestionFlowLogicContainer
					filters={{
						...task.chain?.database_chain_logic?.filters,
						...action.questionaire?.filters,
						items: [
							...(task.chain?.database_chain_logic?.filters?.items ?? []),
							...(action.questionaire?.filters.items ?? []),
						],
					}}
					acceptedQuestionColumns={action.questionaire?.columns ?? []}
					questionScope={action.questionaire?.scope ?? []}
				/>
			)
	}
}
