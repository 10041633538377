import React, { ComponentType, useMemo } from 'react'
import { useSelector } from 'react-redux'
import Chip from '@mui/material/Chip'
import Badge from '@mui/material/Badge'

import { ChevronRight } from 'src/assets/icons'
import { Box, Text } from 'src/components'
import { colors } from 'src/theme/colors'
import { ProjectSection } from 'src/store/modules/projects-v3/selectors'
import { selectors as notesSelectors } from 'src/store/modules/notes/selectors'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { ChainList } from 'src/components/section-tasks-v3/chains-list'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

type SectionListItemProps = {
	section: ProjectSection
}

export const SectionListItem: ComponentType<SectionListItemProps> = ({ section }) => {
	const { selectedProjectId, selectedSectionId, goToSection } = useMyTasks()
	const unreadTaskIds = useSelector(notesSelectors.getUnreadTaskIds)
	const projectTasks = useSelector(projectSelectors.getProjectTasks)
	const sectionUnreadTasks = section.tasks.filter((_task) =>
		unreadTaskIds.includes(_task._id),
	).length

	const isBlockedTasksSection = useMemo(() => {
		const splitId = section._id.split('--')
		return splitId.length > 1 && splitId[1] === 'blocked'
	}, [section._id])

	const isProjectSelected = useMemo(() => {
		return section._id === selectedSectionId
	}, [selectedSectionId, section, selectedProjectId, projectTasks])

	const backgroundColor = useMemo(() => {
		if (isProjectSelected && isBlockedTasksSection) {
			return colors.error.main
		} else if (isProjectSelected) {
			return colors.sunglow['10']
		}

		if (isBlockedTasksSection) {
			return colors.error.light['50']
		}

		return 'white'
	}, [isProjectSelected, isBlockedTasksSection])

	const handleClickSection = (e: React.MouseEvent) => {
		e.stopPropagation()
		goToSection({ name: section.name, chain: section.chain })
	}

	return (
		<Box
			onClick={handleClickSection}
			borderLeft="4px solid"
			bgcolor={backgroundColor}
			borderColor={colors.feldgrau['40']}
			color={isBlockedTasksSection ? 'white' : undefined}
			fontSize={16}
			px={2}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				cursor: 'pointer',
				':hover': {
					backgroundColor: isBlockedTasksSection ? colors.error.main : colors.sunglow['10'],
				},
			}}
		>
			<Box py={2}>
				<Text fontSize={14}>{section.name}</Text>
				{!!section.chain?._id && <ChainList chain={section.chain} />}
			</Box>
			<Box display="flex" py={1} alignItems="center">
				{section.isBlocked ? (
					!isBlockedTasksSection && (
						<Chip label="blocked" color="error" variant="status" size="small" sx={{ mr: 3 }} />
					)
				) : (
					<Badge color="error" badgeContent={sectionUnreadTasks}>
						<Box>
							<Box
								bgcolor={colors.sunglow['40']}
								mr={1}
								borderRadius="10px"
								p={1}
								width={30}
								height={30}
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Text color={colors.sunglow['100']} variant="overline">
									{section.tasks.filter((_task) => !_task.lifecycle.complete).length}
								</Text>
							</Box>
						</Box>
					</Badge>
				)}
				<ChevronRight stroke={isBlockedTasksSection ? '#fff' : undefined} />
			</Box>
		</Box>
	)
}
