import { V3BlueprintTypes, V3ClientTypes, V3ProjectTypes, V3ServerTypes } from '@cango-app/types'
import { v4 } from 'uuid'

import { SelectedOptions } from 'src/providers/task-provider/types'

import { buildFilterModel, buildParentChains } from '../utils'
import { CompletingTask } from '../types'

type StepWithOption = {
	stepId: string
	option: V3ProjectTypes.CompletedOption
}

const getStepsWithOptions = ({
	descendant,
	selectedOptions,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	selectedOptions: V3ProjectTypes.CompletedOption[]
}): StepWithOption[] => {
	const stepId = descendant.step?._id
	if (!stepId) {
		return []
	}
	const stepsWithOptions: StepWithOption[] = []

	const optionCondition = descendant.option_condition

	const isAnyCondition =
		!optionCondition || optionCondition?.operator === V3BlueprintTypes.ChildConditionOperator.Any

	selectedOptions.forEach((_option) => {
		const isMatchingCondition =
			optionCondition?.operator === V3BlueprintTypes.ChildConditionOperator.Is &&
			optionCondition.values.some((_val) => _val === _option._id)
		const isNotMatchingCondition =
			optionCondition?.operator === V3BlueprintTypes.ChildConditionOperator.IsNot &&
			descendant.option_condition?.values.every((_val) => _val !== _option._id)

		if (isAnyCondition || isMatchingCondition || isNotMatchingCondition) {
			stepsWithOptions.push({
				stepId: stepId,
				option: _option,
			})
			return
		}
	})

	return stepsWithOptions
}

const createChain = ({
	completingTask,
	descendant,
	stepWithOption,
}: {
	completingTask: CompletingTask
	descendant: V3ClientTypes.Project.ProjectDescendant
	stepWithOption: StepWithOption
}): V3ProjectTypes.ProjectChain => {
	const parentChains = completingTask?.chain?.parent_chains ?? []

	return {
		...(descendant.thread ?? {}),
		_id: v4(),
		parent_chains: buildParentChains({ parentChains, parentTask: completingTask, descendant }),
		option_id: stepWithOption.option._id,
		label: {
			prefix: descendant.thread?.prefix,
			selected_option: stepWithOption.option.label,
			columnType: stepWithOption.option.column?.type,
			color: descendant.thread?.color,
		},
		database_chain_logic: {
			filters: buildFilterModel({
				descendant,
				options: {
					columnId: stepWithOption.option.column?._id,
					labels: [stepWithOption.option.label],
				},
			}),
		},
		original_descendant_id: descendant._id,
	}
}

const createUnlinkedTasksWithChain = ({
	stepsWithOptions,
	descendant,
	completingTask,
}: {
	stepsWithOptions: StepWithOption[]
	descendant: V3ClientTypes.Project.ProjectDescendant
	completingTask: CompletingTask
}): { tasks: V3ServerTypes.Project.Task[]; chains: V3ProjectTypes.ProjectChain[] } => {
	return stepsWithOptions.reduce(
		(
			_acc: {
				tasks: V3ServerTypes.Project.Task[]
				chains: V3ProjectTypes.ProjectChain[]
			},
			_stepWithOption,
		) => {
			const blueprintStep = descendant.step
			if (!blueprintStep) {
				return _acc
			}
			const chain = createChain({
				completingTask,
				descendant,
				stepWithOption: _stepWithOption,
			})
			_acc.chains.push(chain)
			_acc.tasks.push({
				...blueprintStep,
				_id: v4(),
				chain,
				step: _stepWithOption.stepId,
				project_id: completingTask.project_id,
				notes: [],
				annotations: [],
				parent: completingTask._id,
				assignees: [],
				lifecycle: {
					complete: false,
					completed_meta: [],
					completed_options: [],
				},
				unblock_task: undefined,
				section: completingTask.section,
				iteration: 1,
				actions: [],
				attachments: [],
			})

			return _acc
		},
		{
			tasks: [],
			chains: [],
		},
	)
}

export const createTasksFromMultiSelect = ({
	descendant,
	selectedOptions,
	completingTask,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	selectedOptions: SelectedOptions
	completingTask: CompletingTask
}): { tasks: V3ServerTypes.Project.Task[]; chains: V3ProjectTypes.ProjectChain[] } => {
	const stepsWithOptions = getStepsWithOptions({ selectedOptions, descendant })
	return createUnlinkedTasksWithChain({
		stepsWithOptions,
		descendant,
		completingTask,
	})
}
