import { ComponentType, useContext } from 'react'
import { useSelector } from 'react-redux'

import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers'
import { CompleteTaskButton } from 'src/modules/my-tasks-v3/components/complete-task-cta/complete-task-button'
import { useProject } from 'src/modules/projects-v3/useProject'
import { OnPostCompleteTaskProps } from 'src/modules/my-tasks-v3/components/complete-task-cta/types'

export const CompleteTaskOps: ComponentType = () => {
	const project = useSelector(projectSelectors.getSelectedProject)
	const { task } = useContext(TaskContext)
	const { goToTask } = useProject()

	const handlePostTaskComplete = (data: OnPostCompleteTaskProps) => {
		goToTask(data.nextTask?._id)
	}

	if (!task) {
		return null
	}

	if (project?.archived.state) {
		return null
	}

	return <CompleteTaskButton onPostComplete={handlePostTaskComplete} />
}
