import { ComponentType, useCallback, useContext, useMemo } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { V3BlueprintTypes, V3ProjectTypes, TableTypes } from '@cango-app/types'
import _uniq from 'lodash/uniq'
import { useSelector } from 'react-redux'

import { useListOptions } from 'src/hooks/use-list-options'
import { TableContext, TaskContext } from 'src/providers'
import { Select } from 'src/components'
import { getDatabaseRowUpdates } from 'src/modules/my-tasks-v3/components/complete-task-cta/utils'
import { filterDescendantsBySelection } from 'src/providers/task-provider/selectors'
import { RootState } from 'src/store/types'
import { selectors as projectSelectors } from 'src/store/modules/projects-v3'

type OptionsSelectProps = {
	onChangeRowIdsUpdating: (rowIds: string[]) => void
}

export const OptionsSelect: ComponentType<OptionsSelectProps> = ({ onChangeRowIdsUpdating }) => {
	const { task, selectedOptions, setSelectedOptions } = useContext(TaskContext)
	const {
		mappedColumns,
		resolvedRows,
		mappedRecords,
		updateRecords,
		resetCachedRecord,
		columnFilterList,
		blueprintRecords,
		discardAllChanges,
	} = useContext(TableContext)
	const isMenu = task?.step?.isMenu ?? !!task?.isMenu
	const chainTasks = useSelector((state: RootState) =>
		projectSelectors.getProjectTasksInChain(state, task),
	)

	const listOptions = useListOptions({
		optionsWithFilter: task?.step?.complete_options ?? { options: [] },
		filterModel: task?.chain?.database_chain_logic?.filters,
	})

	const mappedOptions = useMemo(() => {
		return new Map(
			listOptions.map((_option) => [
				_option._id,
				{
					label: _option.label,
					dueTime: _option.dueTime,
					originalValue: _option.originalValue,
				},
			]),
		)
	}, [listOptions])

	const handleSelectOption = useCallback(
		async (e: SelectChangeEvent<unknown>) => {
			let preValues: string[]
			if (isMenu) {
				preValues = e.target.value as string[]
			} else {
				preValues = [e.target.value as string]
			}

			const missingOptions = new Map<
				string,
				V3ProjectTypes.CompletedOption & {
					dueTime?: {
						time: string
						when: V3BlueprintTypes.WhenDueTime
					}
				}
			>()
			task?.lifecycle.completed_options.forEach((_completedOption) => {
				if (
					task.isMultiUse &&
					task.isMenu &&
					!preValues.some((_id) => _id === _completedOption._id)
				) {
					preValues.push(_completedOption._id)
					missingOptions.set(_completedOption._id, _completedOption)
				}
			})
			const newSelectedOptions = preValues.reduce(
				(
					_acc: Array<
						V3ProjectTypes.CompletedOption & {
							dueTime?: {
								time: string
								when: V3BlueprintTypes.WhenDueTime
							}
						}
					>,
					val,
				) => {
					const existingMissingOption = missingOptions.get(val)
					if (existingMissingOption) {
						_acc.push(existingMissingOption)
						return _acc
					}
					const option = mappedOptions.get(val)
					const column =
						typeof task?.step?.complete_options?.options === 'string'
							? mappedColumns.get(task.step.complete_options.options)
							: undefined
					if (!option) {
						return _acc
					}
					const columnType = column?.type
					if (
						columnType &&
						[
							TableTypes.FieldType.SINGLE_SELECT,
							TableTypes.FieldType.REFERENCE,
							TableTypes.FieldType.TABLE_SELECT,
						].includes(columnType)
					) {
						_acc.push({
							_id: val,
							label: option.label,
							dueTime: option.dueTime,
							column: column ? { type: column.type, _id: column._id } : undefined,
						})
					} else {
						_acc.push({
							_id: val,
							label: option?.originalValue ? String(option.originalValue) : option.label,
							dueTime: option.dueTime,
							column: column ? { type: column.type, _id: column._id } : undefined,
						})
					}
					return _acc
				},
				[],
			)

			if (task?.step?.descendants.some(({ database_changes }) => !!database_changes)) {
				discardAllChanges()
				const filteredDescendantsBySelection = filterDescendantsBySelection(
					task,
					task.step?.descendants ?? [],
					chainTasks,
					newSelectedOptions,
				)
				const { rowUpdates, newRows, rowsToReset } = getDatabaseRowUpdates({
					resolvedRows,
					task,
					columnsWithFilterType: columnFilterList,
					descendants: filteredDescendantsBySelection,
					newSelectedOptions,
					previousSelectedOptions: selectedOptions,
					mappedRecords,
					mappedColumns,
					blueprintRecords,
				})

				if (rowsToReset.length) {
					await resetCachedRecord(rowsToReset)
				}

				if (rowUpdates.length || newRows.length) {
					await updateRecords({ rows: rowUpdates, newRows })
				}
				onChangeRowIdsUpdating(
					_uniq([
						...rowUpdates.map(({ newRecord }) => newRecord._id),
						...newRows.map(({ _id }) => _id),
					]),
				)
			}

			setSelectedOptions([...newSelectedOptions])
		},
		[
			task,
			mappedOptions,
			mappedColumns,
			mappedRecords,
			resolvedRows,
			columnFilterList,
			selectedOptions,
			blueprintRecords,
		],
	)

	return (
		<Select
			label={`Choose ${isMenu ? 'options' : 'an option'}`}
			multiline={isMenu}
			multiple={isMenu}
			options={listOptions}
			value={selectedOptions.map(({ _id }) => _id) as string[]}
			onChange={handleSelectOption}
			sx={{ width: 300 }}
			containerProps={{
				width: { mobile: '100%', laptop: undefined },
				minWidth: 300,
				mr: { laptop: 1 },
				mb: { mobile: 1, laptop: 0 },
			}}
		/>
	)
}
