import { useEditor, Editor } from '@tiptap/react'
import { ComponentType, createContext, PropsWithChildren, useContext } from 'react'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import Underline from '@tiptap/extension-underline'
import Highlight from '@tiptap/extension-highlight'

import { colors } from 'src/theme/colors'

const extensions = [
	StarterKit,
	Underline,
	Highlight,
	TextAlign.configure({
		types: ['heading', 'paragraph'],
	}),
	Table.configure({
		resizable: true,
		allowTableNodeSelection: true,
		HTMLAttributes: {
			class: 'tableWrapper',
		},
	}),
	TableRow,
	TableHeader,
	TableCell,
]

const EditorContext = createContext<{ editor: Editor | null }>({
	editor: null,
})

export const EditorProvider: ComponentType<PropsWithChildren<{ defaultContent?: string }>> = ({
	children,
	defaultContent,
}) => {
	const editor = useEditor({
		extensions,
		content: defaultContent,
		injectCSS: false,
		editorProps: {
			attributes: {
				style: `min-height:500px; box-sizing: border-box; border: 2px solid ${colors.feldgrau['60']}; outline: ${colors.neutral['50']}; border-radius: 8px; padding: 0px 20px; font-family: 'Open Sans'; overflow-wrap: break-word; word-wrap: break-word;`,
			},
		},
	})

	return (
		<EditorContext.Provider
			value={{
				editor,
			}}
		>
			{children}
		</EditorContext.Provider>
	)
}

export const useRichEditor = () => {
	const { editor } = useContext(EditorContext)

	if (editor === undefined) {
		throw new Error('useRichEditor must be used within a EditorProvider')
	}
	return {
		editor,
	}
}
