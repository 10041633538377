import { ComponentType, useState } from 'react'
import MuiTimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import dayjs from 'dayjs'
import { TasksSdk } from '@cango-app/sdk'
import { V3ClientTypes } from '@cango-app/types'
import PulseLoader from 'react-spinners/PulseLoader'
import Alert from '@mui/material/Alert'
import { useSelector } from 'react-redux'

import { ChevronDown, OpenTabLink } from 'src/assets/icons'
import { Box, Button, FormCard, Text } from 'src/components'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { TaskFiles } from 'src/modules/my-tasks-v3/components/actions/file-tasks/task-files'
import { RouteId } from 'src/constants/routes'

type TimelineItemProps = {
	hierarchyItem: TasksSdk.HierarchyItem
	hideConnector?: boolean
	customContent?: React.ReactNode
}

type HistoryTaskData = Pick<V3ClientTypes.Project.Task, '_id' | 'notes' | 'actions'>

const TaskDataComponent: ComponentType<{
	isLoading: boolean
	hasError: boolean
	taskData?: HistoryTaskData
}> = ({ isLoading, hasError, taskData }) => {
	const openChatClick = () => {
		window.open(`${window.location.origin}/${RouteId.Inbox}/all/${taskData?._id}`, '_blank')
	}

	if (isLoading) {
		return (
			<Box flex={1} display="flex" justifyContent="center" alignItems="center" height="100%">
				<PulseLoader size={8} />
			</Box>
		)
	}

	if (hasError) {
		return (
			<Alert severity="error">An error occurred while fetching task data. Please try again</Alert>
		)
	}

	if (!taskData) {
		return null
	}

	if (!taskData.notes?.length && taskData.actions.every((_action) => !_action.file_ids.length)) {
		return <Alert severity="info">No data available</Alert>
	}

	return (
		<Box display="flex" flexDirection="column" alignItems="center" px={6}>
			{taskData.actions.map((_action, actionIndex) => {
				if (!_action.file_ids.length) return null
				return (
					<FormCard title="Files" width="100%" key={_action._id}>
						<TaskFiles action={_action} hideHistory actionIndex={actionIndex} />
					</FormCard>
				)
			})}
			{!!taskData.notes?.length && (
				<Button endIcon={<OpenTabLink width={16} />} variant="outlined" onClick={openChatClick}>
					Go to task chat
				</Button>
			)}
		</Box>
	)
}

export const TimelineItem: ComponentType<TimelineItemProps> = ({
	hierarchyItem,
	hideConnector,
	customContent,
}) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [isTaskDataOpen, setIsTaskDataOpen] = useState(false)
	const [taskData, setTaskData] = useState<HistoryTaskData>()
	const [isLoading, setIsLoading] = useState(false)
	const [hasError, setHasError] = useState(false)

	const handleTaskClick = async () => {
		if (isTaskDataOpen) {
			setIsTaskDataOpen(false)
			return
		}

		setIsTaskDataOpen(true)

		if (!taskData) {
			try {
				setIsLoading(true)
				const task = await TasksSdk.getTask<HistoryTaskData>(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						taskId: hierarchyItem._id,
						project: ['notes', 'actions'],
					},
				)
				setTaskData(task)
				setIsTaskDataOpen(true)
			} catch (error) {
				setHasError(true)
			} finally {
				setIsLoading(false)
			}
		}
	}

	return (
		<>
			<MuiTimelineItem>
				{
					<TimelineOppositeContent color="textSecondary" fontSize={14}>
						{!!hierarchyItem.lifecycle?.completed_meta?.length &&
							hierarchyItem.lifecycle.completed_meta[0] &&
							dayjs
								.unix(hierarchyItem.lifecycle?.completed_meta[0].at)
								.format('MMM DD, YYYY HH:mm')}
					</TimelineOppositeContent>
				}
				{!isTaskDataOpen && (
					<TimelineSeparator>
						<TimelineDot />
						{!hideConnector && <TimelineConnector />}
					</TimelineSeparator>
				)}
				<TimelineContent>
					<Box>
						<Box
							display="flex"
							alignItems="center"
							mb={1}
							onClick={handleTaskClick}
							sx={{ ':hover': { cursor: 'pointer' } }}
						>
							<Text>{hierarchyItem.name}</Text>
							<ChevronDown
								style={{ marginLeft: 4, transform: isTaskDataOpen ? 'rotate(180deg)' : '' }}
							/>
						</Box>
						{customContent}
					</Box>
				</TimelineContent>
			</MuiTimelineItem>
			{isTaskDataOpen && (
				<Box minHeight={24} mb={5}>
					<TaskDataComponent isLoading={isLoading} hasError={hasError} taskData={taskData} />
				</Box>
			)}
		</>
	)
}
