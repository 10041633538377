import { ComponentType, useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk'

import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { useIsMobile } from 'src/hooks/useMobile'
import { DriveFilesContext, DriveFilesProvider } from 'src/providers'
import { selectors as authSelectors } from 'src/store/modules/auth'
import usePolling from 'src/hooks/usePolling'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'

import { MobileSectionContainer } from './mobile-section-container'
import { DesktopSectionContainer } from './desktop-section-container'

const SectionContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const isMobile = useIsMobile()
	const { selectedProjectId, selectedSectionId } = useMyTasks()
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)
	const { addNewFilesToCollection } = useParentFolderFiles(
		parentFolderId as string,
		parentFolderName as string,
	)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const projectList = useSelector(projectSelectors.getProjectlist)
	const selectedSection = useSelector(projectSelectors.getProjectSection)
	const sectionTasksPendingUpload = useSelector(projectSelectors.getSectionTasksPendingUpload)
	const isPendingFileUploads = sectionTasksPendingUpload.length

	const getTasksPendingUploadedFiles = useCallback(async () => {
		if (!selectedSection) return
		try {
			const response = await V3ProjectSdk.getTasksPendingUploadedFiles({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				data: {
					taskIds: sectionTasksPendingUpload.map((_task) => _task._id),
				},
			})
			if (response.length) {
				dispatch(projectActions.updateTaskFileIds(response))
				addNewFilesToCollection()
			}
		} catch (error) {
			showSnackbar('Failed to fetch pending uploads. Please refresh and try again.', {
				variant: 'error',
			})
		}
	}, [sectionTasksPendingUpload, selectedSection])
	const [isPolling, startPolling, stopPolling] = usePolling(getTasksPendingUploadedFiles, 1000)

	useEffect(() => {
		if (!isPolling && selectedSection && isPendingFileUploads) {
			startPolling()
		}

		if (isPolling && (!isPendingFileUploads || !selectedSection)) {
			dispatch(projectActions.setFilesTotalToUpload(0))
			stopPolling()
		}

		return () => {
			if (isPolling) {
				stopPolling()
			}
		}
	}, [isPendingFileUploads, isPolling, selectedSection])

	useEffect(() => {
		if (!selectedSectionId && !projectList.length) {
			return
		}

		if (selectedSection?.tasks.some((_task) => _task.section === selectedSectionId)) {
			return
		}

		if (!selectedSectionId && isMobile) {
			return
		}
	}, [selectedSectionId, projectList.length])

	useEffect(() => {
		if (selectedProjectId) {
			getTasksPendingUploadedFiles()
		}
	}, [selectedProjectId])

	if (isMobile) {
		return <MobileSectionContainer />
	}

	return <DesktopSectionContainer />
}

const SectionWithDriveProvider: ComponentType = () => {
	const project = useSelector(projectSelectors.getSelectedProject)

	return (
		<DriveFilesProvider
			parentFolderName={project?.name}
			parentFolderId={project?.google_drive_folder_id}
		>
			<SectionContainer />
		</DriveFilesProvider>
	)
}

export default SectionWithDriveProvider
