import { ComponentType, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Stack } from '@mui/material'

import { Box, IconButton, Modal, Text } from 'src/components'
import { BackArrowOutlined, HistoryIcon, InboxNavIcon, TagIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { useIsMobile } from 'src/hooks/useMobile'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { selectors as notesSelectors } from 'src/store/modules/notes'
import TaskHierarchyComponent from 'src/modules/projects-v3/project-detail/task-drawer/configure-task/task-hierarchy'
import { useMyTasks } from 'src/hooks/useMyTasks'
import { TaskContext } from 'src/providers'
import { OnDemandChainSelection } from 'src/modules/my-tasks-v3/components/on-demand-chain-selection'

import { TaskTitle } from './task-title'

export const TopButtonBar: ComponentType = () => {
	const dispatch = useDispatch()
	const { selectedSectionId, goToSection } = useMyTasks()
	const { task: selectedTask } = useContext(TaskContext)
	const unreadTasks = useSelector(notesSelectors.getTasksWithUnreadNotes)
	const [isHistoryOpen, setIsHistoryOpen] = useState(false)
	const [isNotesOpen, setIsNotesOpen] = useState(false)
	const isMobile = useIsMobile()
	const projectWideChains = useSelector(projectSelectors.getProjectWideChains)

	const hasUnreadTasks = useMemo(() => {
		if (!selectedTask) return false
		return unreadTasks.find(({ taskId }) => taskId === selectedTask._id)
	}, [unreadTasks, selectedTask])

	const handleCommentsClick = () => {
		dispatch(projectActions.toggleChatModal(true))
	}

	const handleBackClick = () => {
		goToSection(selectedSectionId)
	}

	if (!selectedTask) return null

	return (
		<>
			<Modal open={isHistoryOpen} onClose={() => setIsHistoryOpen(false)}>
				<Box minHeight={400} maxHeight="90vh" minWidth={700}>
					<TaskHierarchyComponent
						_task={selectedTask}
						onResetTaskComplete={(data) => dispatch(projectActions.resetTask(data))}
					/>
				</Box>
			</Modal>
			<Modal
				open={isNotesOpen}
				onClose={() => setIsNotesOpen(false)}
				containerStyle={{
					width: 650,
					maxWidth: '100%',
					height: 500,
					maxHeight: '100%',
					flex: 1,
					display: 'flex',
				}}
				sx={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Box height={350} maxHeight="70vh" flex={1} p={1}>
					<Text variant="overline">Notes</Text>
					{selectedTask.annotations.map(({ annotation, _id }) => {
						return (
							<Stack key={_id} my={1} px={2} py={2} borderRadius={4} bgcolor={colors.neutral['30']}>
								<Text>{annotation}</Text>
							</Stack>
						)
					})}
				</Box>
			</Modal>
			<Box
				display="flex"
				flex={1}
				borderBottom="1px solid"
				borderColor={colors.neutral['40']}
				py={2}
			>
				<Box display="flex" flex={1}>
					<Box display="flex" alignItems="center">
						{!isMobile && (
							<IconButton onClick={handleBackClick}>
								<BackArrowOutlined />
							</IconButton>
						)}
						<TaskTitle
							task={selectedTask}
							containerProps={{ flex: 1, ml: { laptop: 2 } }}
							fontSize={isMobile ? 14 : 16}
							hideIcon={isMobile}
						/>
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					{!!projectWideChains.length && (
						<OnDemandChainSelection chainId={selectedTask.chain?._id} />
					)}
					<IconButton onClick={() => setIsHistoryOpen(true)} tooltip="History">
						<HistoryIcon />
					</IconButton>
					<IconButton aria-label="open notes" onClick={() => setIsNotesOpen(true)} tooltip="Notes">
						<Badge color="warning" badgeContent={selectedTask.annotations.length || 0}>
							<TagIcon />
						</Badge>
					</IconButton>
					<Badge
						color="error"
						badgeContent={hasUnreadTasks || selectedTask.isFlagged ? '!' : undefined}
					>
						<IconButton onClick={handleCommentsClick} tooltip="Task inbox">
							<InboxNavIcon fill={colors.feldgrau['60']} />
						</IconButton>
					</Badge>
				</Box>
			</Box>
		</>
	)
}
