import React, { ComponentType, useContext, useMemo } from 'react'
import { V3ClientTypes } from '@cango-app/types'

import { AttachedFile, Box } from 'src/components'
import { DriveFilesContext, TaskContext } from 'src/providers'
import { useParentFolderFiles } from 'src/components/drive-upload/use-parent-folder-files'

import { TaskFileHistory } from '../actions/file-tasks/file-history'

type TaskFilesProps = {
	action: V3ClientTypes.Project.TaskAction
	hideHistory?: boolean
	actionIndex: number
}

export const AttachmentTaskFiles: ComponentType<TaskFilesProps> = ({
	action,
	hideHistory = false,
	actionIndex,
}) => {
	const { parentFolderId, parentFolderName } = useContext(DriveFilesContext)
	const { updateTask, task } = useContext(TaskContext)
	const { allFiles } = useParentFolderFiles(parentFolderId as string, parentFolderName as string)

	const historicalFiles =
		action.file_history?.filter((_history) => !action.file_ids.includes(_history)) ?? []

	const allFileIds = action.file_ids ?? []

	const mappedFiles = useMemo(() => {
		return new Map(
			allFileIds.map((_fileId) => [_fileId, allFiles.find((file) => file.id === _fileId)]),
		)
	}, [allFiles, allFileIds])

	const handleRemoveFileFromTask = (status: 'history' | 'live') => async (taskId: string) => {
		if (actionIndex < 0 || !task) {
			return
		}
		const copiedAttachment = { ...action }
		if (status === 'history') {
			copiedAttachment.file_history = copiedAttachment.file_history?.filter(
				(_fileId) => _fileId !== taskId,
			)
		} else if (status === 'live') {
			copiedAttachment.file_ids = copiedAttachment.file_ids?.filter((_fileId) => _fileId !== taskId)
		}
		const actions = [...task.attachments]
		actions[actionIndex] = copiedAttachment
		await updateTask('attachments', actions, { updateDb: true })
	}

	return (
		<>
			{!!allFileIds.length && (
				<Box>
					<Box display="flex" flexWrap="wrap">
						{allFileIds.map((fileId) => (
							<AttachedFile
								key={fileId}
								fileId={fileId}
								containerStyles={{ mb: 0.5, mr: 1 }}
								_file={mappedFiles.get(fileId)}
								parentFolderId={parentFolderId}
								parentFolderName={parentFolderName}
								onRemoveFileFromTask={handleRemoveFileFromTask('live')}
							/>
						))}
					</Box>
				</Box>
			)}
			{!!historicalFiles.length && !hideHistory && (
				<TaskFileHistory
					file_history={historicalFiles}
					onRemoveFileFromTask={handleRemoveFileFromTask('history')}
				/>
			)}
		</>
	)
}
