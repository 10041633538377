import _isString from 'lodash/isString'
import { TableTypes, V3ClientTypes, V3ProjectTypes, V3ServerTypes } from '@cango-app/types'
import { v4 } from 'uuid'
import _cloneDeep from 'lodash/cloneDeep'

import { SelectedOptions } from 'src/providers/task-provider/types'
import { ColumnFilterList, ResolvedRowData } from 'src/providers/table-provider/types'
import { applyFilterModelToRow, getFilteredChains, getFilters } from 'src/modules/tables/utils'

import { CompletingTask, TaskDbChain } from '../types'
import { buildFilterModel, buildParentChains, createInstanceChain } from '../utils'

export const getUpdatedDescendantWithSelectedOptions = ({
	descendant,
	selectedOptions,
	projectTasks,
	completingTask,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	selectedOptions: SelectedOptions
	projectTasks: V3ClientTypes.Project.Task[]
	completingTask: CompletingTask
}): V3ClientTypes.Project.ProjectDescendant => {
	const descendantCopy = _cloneDeep(descendant)
	if (!descendantCopy.step || !descendantCopy.database_chain_logic) {
		return descendantCopy
	}

	if (
		descendantCopy.option_condition?.add_selection_to_database_chain_filters &&
		selectedOptions.length
	) {
		const fromTask =
			projectTasks.find((_task) => _task._id === descendantCopy.option_condition?.from) ??
			completingTask
		let completed_options: V3ProjectTypes.CompletedOption[]
		if (fromTask._id === completingTask._id) {
			completed_options = selectedOptions
		} else {
			completed_options = fromTask.lifecycle.completed_options.filter((_option) =>
				descendantCopy.option_condition?.values.includes(_option._id),
			)
		}
		if (_isString(completingTask.step?.complete_options?.options)) {
			descendantCopy.database_chain_logic = {
				...descendantCopy.database_chain_logic,
				filters: {
					...descendantCopy.database_chain_logic.filters,
					items: [
						...descendantCopy.database_chain_logic.filters.items,
						{
							field: completingTask.step?.complete_options?.options,
							operator: 'isAnyOf',
							value: completed_options.map(({ label }) => label),
						},
					],
				},
			}
		}
	}

	// TODO disable being able to set logic between a section and a task (force all logic to happen between previous task and section)

	return descendantCopy
}

const getNewChainLabels = ({
	resolvedRows,
	completingTask,
	columnFilterList,
	projectTasks,
	descendant,
	mappedColumns,
}: {
	completingTask: CompletingTask
	resolvedRows: ResolvedRowData[]
	columnFilterList: ColumnFilterList
	projectTasks: V3ClientTypes.Project.Task[]
	descendant: V3ClientTypes.Project.ProjectDescendant
	mappedColumns: Map<string, TableTypes.Field>
}): TaskDbChain[] => {
	const databaseLogic = descendant.database_chain_logic
	if (!databaseLogic) {
		return []
	}
	const column = mappedColumns.get(databaseLogic.column)
	if (!column) {
		return []
	}
	const mappedColumnLabels = new Map<string, string>(
		resolvedRows.reduce((acc: [string, string][], _row) => {
			const label = _row[databaseLogic.column]
			if (!label) {
				return acc
			}
			return [...acc, [_row._id, String(label)]]
		}, []),
	)

	const nextChains: Pick<V3ProjectTypes.ProjectChain, 'database_chain_logic'>[] = getFilteredChains(
		descendant,
		completingTask.chain,
	)

	if (descendant.database_chain_logic) {
		nextChains.push({
			database_chain_logic: descendant.database_chain_logic,
		})
	}

	const filters = getFilters({
		chains: nextChains,
		mappedColumns,
	})

	const uniqueLabels = resolvedRows.reduce((_uniqueLabels: Set<string>, _row) => {
		const doesRowCompleteNextLogic = applyFilterModelToRow({
			columns: columnFilterList,
			row: _row,
			filterModel: {
				items: filters,
			},
		})

		if (!doesRowCompleteNextLogic) {
			return _uniqueLabels
		}

		const label = mappedColumnLabels.get(_row._id)
		const chainExists = projectTasks.some(
			({ chain: _chain }) =>
				_chain?.label &&
				_chain.label.selected_option === label &&
				_chain.label.prefix === databaseLogic.column &&
				_chain.original_descendant_id === descendant._id,
		)

		if (label && !chainExists) {
			_uniqueLabels.add(label)
		}

		return _uniqueLabels
	}, new Set<string>())

	return [...uniqueLabels].map((_label) => ({
		_id: v4(),
		labels: [_label],
		column,
	}))
}

const buildDatabaseChainLabel = ({
	descendant,
	dbChain,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	dbChain: TaskDbChain
}): V3ProjectTypes.ProjectChain['label'] => {
	const prefix = dbChain.prefix ?? dbChain.column._id ?? ''
	const selected_option = dbChain.labels.join(', ')
	return {
		prefix,
		selected_option,
		color: descendant.thread?.color,
		columnType: dbChain.column.type,
	}
}

const createChain = ({
	descendant,
	completingTask,
	dbChain,
	instanceName,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	completingTask: CompletingTask
	dbChain: TaskDbChain
	instanceName: string | undefined
}):
	| { mainChain: V3ProjectTypes.ProjectChain; instanceChain?: V3ProjectTypes.ProjectChain }
	| undefined => {
	if (!descendant.database_chain_logic) {
		return
	}
	const parentChains = completingTask.chain?.parent_chains ?? []
	let instanceChain: V3ProjectTypes.ProjectChain | undefined
	if (instanceName) {
		instanceChain = createInstanceChain(instanceName, parentChains)
		parentChains.push(instanceChain)
	}

	const filterModel = buildFilterModel({
		descendant,
		options: {
			columnId: dbChain.column._id,
			labels: dbChain.labels,
		},
	})

	return {
		mainChain: {
			_id: v4(),
			parent_chains: buildParentChains({ parentChains, parentTask: completingTask, descendant }),
			option_id: '',
			label: buildDatabaseChainLabel({ descendant, dbChain }),
			database_chain_logic: {
				filters: filterModel,
			},
			original_descendant_id: descendant._id,
		},
		instanceChain: instanceChain,
	}
}

const createUnlinkedTasksWithChains = ({
	completingTask,
	descendant,
	taskDbChains,
	instanceName,
}: {
	completingTask: CompletingTask
	descendant: V3ClientTypes.Project.ProjectDescendant
	taskDbChains: TaskDbChain[]
	instanceName: string | undefined
}): { tasks: V3ServerTypes.Project.Task[]; chains: V3ProjectTypes.ProjectChain[] } => {
	const blueprintStep = descendant.step
	if (!blueprintStep) {
		return {
			tasks: [],
			chains: [],
		}
	}
	return taskDbChains.reduce(
		(
			_acc: {
				tasks: V3ServerTypes.Project.Task[]
				chains: V3ProjectTypes.ProjectChain[]
			},
			_taskDbChain,
		) => {
			const chain = createChain({ descendant, completingTask, dbChain: _taskDbChain, instanceName })
			if (!chain) {
				return _acc
			}
			const { mainChain, instanceChain } = chain
			_acc.chains.push(...[mainChain, ...(instanceChain ? [instanceChain] : [])])
			_acc.tasks.push({
				...blueprintStep,
				_id: v4(),
				chain: mainChain,
				step: blueprintStep._id,
				project_id: completingTask.project_id,
				notes: [],
				annotations: [],
				parent: completingTask._id,
				assignees: [],
				lifecycle: {
					complete: false,
					completed_meta: [],
					completed_options: [],
				},
				unblock_task: undefined,
				section: completingTask.section,
				iteration: 1,
				actions: [],
				attachments: [],
			})
			return _acc
		},
		{
			tasks: [],
			chains: [],
		},
	)
}

export const createTasksFromDatabaseLogic = ({
	descendant,
	selectedOptions,
	projectTasks,
	completingTask,
	resolvedRows,
	columnFilterList,
	mappedColumns,
	roleDbLogic,
	instanceName,
}: {
	descendant: V3ClientTypes.Project.ProjectDescendant
	selectedOptions: SelectedOptions
	projectTasks: V3ClientTypes.Project.Task[]
	completingTask: CompletingTask
	resolvedRows: ResolvedRowData[]
	columnFilterList: ColumnFilterList
	mappedColumns: Map<string, TableTypes.Field>
	roleDbLogic?: TaskDbChain[]
	instanceName: string | undefined
}): { tasks: V3ServerTypes.Project.Task[]; chains: V3ProjectTypes.ProjectChain[] } => {
	if (!descendant.database_chain_logic) {
		return {
			tasks: [],
			chains: [],
		}
	}

	const updatedDescendantWithFilters = getUpdatedDescendantWithSelectedOptions({
		descendant,
		selectedOptions,
		projectTasks,
		completingTask,
	})

	let taskDbChains = roleDbLogic

	if (!taskDbChains) {
		taskDbChains = getNewChainLabels({
			resolvedRows,
			completingTask,
			columnFilterList,
			projectTasks,
			descendant: updatedDescendantWithFilters,
			mappedColumns,
		})
	}

	return createUnlinkedTasksWithChains({
		completingTask,
		descendant: updatedDescendantWithFilters,
		taskDbChains,
		instanceName,
	})
}
