import { V3BlueprintTypes } from '@cango-app/types'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { v4 } from 'uuid'

import { Box, Button, IconButton, Select } from 'src/components'
import { getActionLabel } from 'src/helpers/labels'
import { TrashIcon } from 'src/assets/icons'

import { StepFormType } from './step-form-container'
import { AttachmentsFields } from './attachments-fields'

const ActionOptions = Object.values(V3BlueprintTypes.ActionEnum).map((action) => ({
	_id: action,
	label: getActionLabel(action),
}))

type Props = {
	chainIndex: number
}

export const AttachmentsList = ({ chainIndex }: Props) => {
	const [filterBy, setFilterBy] = useState<V3BlueprintTypes.ActionEnum[]>([])
	const { control } = useFormContext<StepFormType>()
	const {
		fields: attachments,
		append,
		remove,
		update,
	} = useFieldArray({ control, name: `chain_actions.${chainIndex}.attachments` })

	const filteredAttachments = attachments.filter(({ type }) =>
		filterBy.length > 0 ? filterBy.includes(type) : true,
	)

	return (
		<Stack direction="column" marginTop={2} spacing={3}>
			<Box
				sx={{
					display: 'grid',
					gridTemplateColumns: '1fr 1fr',
					'& > div': {
						border: '1px dotted gray',
					},
					// Remove specific borders
					'& > div:nth-of-type(-n+2)': {
						borderTop: 'none', // Remove the top border of the first row
					},
					'& > div:nth-of-type(2n)': {
						borderRight: 'none', // Remove the right border of the second column
					},

					'& > div:nth-of-type(2n-1)': {
						borderLeft: 'none', // Remove the left border of the first column
					},
					// Handle the last row, even if it has an odd number of items
					'& > div:nth-last-of-type(-n+2)': {
						borderBottom: 'none', // Remove the bottom border of the last row
					},
					'& > div:last-of-type': {
						borderRight: 'none', // If the last item is alone in its row, remove its right border
					},
				}}
			>
				{filteredAttachments.map((attachment, index) => {
					const { _id, type } = attachment
					return (
						<Stack
							key={_id}
							padding={1}
							display="flex"
							flexDirection="column"
							height="220px"
							spacing={2}
						>
							<Stack
								direction="row"
								justifyContent="space-between"
								width="100%"
								alignItems="center"
							>
								<Select
									aria-label="Type"
									options={ActionOptions}
									value={type}
									onChange={(e) => {
										update(index, {
											...attachment,
											type: e.target.value as V3BlueprintTypes.ActionEnum,
										})
									}}
								/>
								<IconButton onClick={() => remove(index)}>
									<TrashIcon />
								</IconButton>
							</Stack>
							<AttachmentsFields type={type} attachmentIndex={index} chainIndex={chainIndex} />
						</Stack>
					)
				})}
				<Box
					display="flex"
					justifyContent="center"
					padding={3}
					gridColumn={filteredAttachments.length % 2 ? 'auto' : 'span 2'}
				>
					<Button
						variant="text"
						onClick={() => {
							setFilterBy([])
							append({
								_id: v4(),
								type: V3BlueprintTypes.ActionEnum.None,
								file_ids: [],
								task_references: [],
								roles: [],
								links: [],
								questionaire: {
									columns: [],
									filters: {
										items: [],
									},
									scope: [],
								},
							})
						}}
					>
						Add attachment
					</Button>
				</Box>
			</Box>
		</Stack>
	)
}
