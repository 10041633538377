import { ComponentType, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { Box, Divider, Select, Text } from 'src/components'
import { colors } from 'src/theme/colors'

import { FileViewItemType } from './file-tasks/file-view'
import { Software } from './software'
import { CompletationInfo } from './file-tasks/completation-info'

export const LinkViewItem: ComponentType<{
	item: FileViewItemType
	isLastItem: boolean
}> = ({ item, isLastItem }) => {
	const [selectedTaskId, setSelectedTaskId] = useState<string>(item.tasks[0]._id)

	const selectedTask = useMemo(() => {
		return item.tasks.find((_task) => _task._id === selectedTaskId)
	}, [selectedTaskId])

	const completedAtTime = selectedTask?.lifecycle?.completed_meta?.length
		? selectedTask.lifecycle?.completed_meta[selectedTask.lifecycle?.completed_meta.length - 1]
		: undefined

	const taskLabel = selectedTask?.chain?._id ? (
		<>
			{item.chain?.label.prefix}: <b>{item.chain?.label.selected_option}</b>
		</>
	) : undefined

	const options = useMemo(() => {
		if (item.tasks.length === 1) return []

		return item.tasks.map((_task) => {
			const _taskCompletedTime = _task.lifecycle?.completed_meta?.length
				? _task.lifecycle?.completed_meta[_task.lifecycle?.completed_meta.length - 1]
				: undefined
			return {
				_id: _task._id,
				label: (
					<Box>
						<Text fontWeight="bold" fontSize={12}>
							Iteration {_task.iteration}
						</Text>
						{!!_taskCompletedTime && (
							<Text fontSize={12}>
								{dayjs.unix(_taskCompletedTime.at).format('MMM DD, YYYY hh:mma')}
							</Text>
						)}
					</Box>
				),
			}
		})
	}, [])

	const allLinks = useMemo(() => {
		return (
			selectedTask?.actions?.reduce((_acc: { _id: string; link: string }[], _action) => {
				_acc = [...new Set([..._acc, ..._action.links])]
				return _acc
			}, []) ?? []
		)
	}, [selectedTask])

	if (!selectedTask?._id) return null

	return (
		<Box key={`add-links-for-${selectedTask._id}`}>
			<Box mb={1}>
				<Text fontSize={14}>
					Task: <b>{item.taskName}</b>
				</Text>
				<Text fontSize={14}>{!!selectedTask.chain?._id && <>{taskLabel}</>}</Text>
				{options.length ? (
					<Select
						options={options}
						value={selectedTask._id}
						onChange={(e) => setSelectedTaskId(e.target.value as string)}
						containerProps={{ sx: { mt: 1 } }}
					/>
				) : (
					<>
						{(selectedTask.iteration ?? 0) > 1 && (
							<Text fontSize={14}>
								Iteration: <b>{selectedTask.iteration}</b>
							</Text>
						)}
						<CompletationInfo completedAtTime={completedAtTime?.at} />
					</>
				)}
			</Box>
			<Software links={allLinks} />
			{!isLastItem && <Divider color={colors.neutral['60']} sx={{ my: 2 }} />}
		</Box>
	)
}
