import { ComponentType, useState } from 'react'
import { useDispatch } from 'react-redux'
import { V3ClientTypes } from '@cango-app/types'

import { actions as projectActions } from 'src/store/modules/projects-v3'
import { Button, IconButton } from 'src/components'
import { AsyncDispatchType } from 'src/store/types'
import { useIsMobile } from 'src/hooks/useMobile'
import { CircledCheckIcon } from 'src/assets/icons'

import { CreateInstanceModal } from './create-instance-modal'

type CreateInstanceCtaProps = {
	task: V3ClientTypes.Project.Task
}

export const MultiUseCta: ComponentType<CreateInstanceCtaProps> = ({ task }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isModalOpen, setIsModalOpen] = useState(false)
	const isMobile = useIsMobile()

	const handleMultiUseAr = async (instanceName: string) => {
		if (!instanceName) return
		const response = await dispatch(
			projectActions.completeTask({
				taskId: task._id,
				projectId: task.project_id,
				taskUpdates: {
					_id: task._id,
				},
				newTasks: [],
			}),
		)
		if (response.meta.requestStatus === 'fulfilled') {
			setIsModalOpen(false)
		}
	}

	return (
		<>
			{isMobile ? (
				<IconButton onClick={() => setIsModalOpen(true)}>
					<CircledCheckIcon />
				</IconButton>
			) : (
				<Button onClick={() => setIsModalOpen(true)}>Create instance</Button>
			)}

			<CreateInstanceModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				task={task}
				onSubmit={handleMultiUseAr}
			/>
		</>
	)
}
